import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import vid from "../../assets/images/hero/SLIDE.mp4";
import banner1 from "../../assets/images/banner/h1-banner-1.jpg";
import banner2 from "../../assets/images/banner/h1-banner-2.jpg";
import banner3 from "../../assets/images/banner/h1-banner-3.jpg";
import banner4 from "../../assets/images/banner/h1-banner-4.jpg";
import banner5 from "../../assets/images/banner/h1-banner-5.jpg";
import banner6 from "../../assets/images/banner/h1-banner-6.jpg"; 
import banner7 from "../../assets/images/banner/h1-banner-7.jpg";
import banner8 from "../../assets/images/banner/h1-banner-8.jpg";
import banner9 from "../../assets/images/banner/h1-banner-9.jpg";
import banner10 from "../../assets/images/banner/h1-banner-10.jpg";
import banner11 from "../../assets/images/banner/h1-banner-11.jpg";
import { FormattedMessage } from "react-intl";



function Home() {
    
      return (


<div id="main-wrapper">

<div className="hero-section ">
    <div className="slideVideo" align="center">
          <video width="100%" height="100%" loop autoPlay muted>
           <source src={vid} type="video/mp4" />
          </video>   
    </div>
</div>
    
<div className="banner-section section pt-30">
    <div className="container-fluid pl-20 pr-20 pl-lg-15 pr-lg-15 pl-md-15 pr-md-15 pl-sm-15 pr-sm-15 pl-xs-15 pr-xs-15" style={{width:"92%"}}>
        <div className="row AboutUs">
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                         <img src={banner1} alt="" />
                    </div>
                    <div className="banner-content">
                        <h3 className="title bannerr"><FormattedMessage id="banner1" defaultMessage="Produits d'intérieur" /></h3>
                        <Link to="/intern"><FormattedMessage id="banner4" defaultMessage="Voir Collection" /><i className="ion-android-arrow-dropright-circle"></i></Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner2} alt="" />
                    </div>
                    <div className="banner-content">
                        <h3 className="title bannerr"><FormattedMessage id="banner2" defaultMessage="Produits d'extérieur" /></h3>
                        <Link to="/extern"><FormattedMessage id="banner4" defaultMessage="Voir Collection" /><i className="ion-android-arrow-dropright-circle"></i></Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner3} alt="" />
                    </div>
                    <div className="banner-content">
                        <h3 className="title bannerr"><FormattedMessage id="banner3" defaultMessage="Profiles et Rubans LED" /></h3>
                        <Link to="/profiles"><FormattedMessage id="banner4" defaultMessage="Voir Collection" /><i className="ion-android-arrow-dropright-circle"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="banner-section section">
    <div className="container-fluid pl-20 pr-20 pl-lg-15 pr-lg-15 pl-md-15 pr-md-15 pl-sm-15 pr-sm-15 pl-xs-15 pr-xs-15" style={{width:"92%"}}>
<div className="row">
            <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner4} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                        <br/>
                        <br/>
                        <br/>
                        <h4 className="title-light" ><FormattedMessage id="home1" defaultMessage="LA LUMIÈRE" /></h4>
                        <h3 className="title"><FormattedMessage id="home2" defaultMessage="DES ESPACES URBAINS" /></h3>
                        <h6 className="col-md-5"><FormattedMessage id="home3" defaultMessage="Des rues bien éclairées et des monuments mis en valeur par des illuminations participent à dynamiser la ville et renforcent le sentiment de sécurité. " /></h6>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner5} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                    <br/>
                    <br/>
                    <br/>
        <h4 className="title-light" ><FormattedMessage id="home11" defaultMessage="L’ÉCLAIRAGE" /></h4>
        <h3 className="title"><FormattedMessage id="home21" defaultMessage="TERTIAIRE BUREAUX" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home31" defaultMessage="Une solution lumière juste aide à augmenter le bien être, la capacité visuelle dans des circonstances difficiles et sur une période prolongée." /></h6>
                    </div>
                </div>
            </div>
  <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner6} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two">
                    <br/>
                     <br/>
                    <br/>
        <h4 className="title-light" ><FormattedMessage id="home12" defaultMessage="AMBIANCE" /></h4>
        <h3 className="title"><FormattedMessage id="home22" values={{ linebreak: <br /> }} defaultMessage="PROPICE À L’ACHAT" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home32" defaultMessage="L’éclairage constitue une part importante de la facture énergétique: jusqu’à 60 % dans certains domaines d’activité (l’habillement, l’alimentation, la pharmacie…)." /> </h6>
                    </div>
                </div>
            </div>
  <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner7} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                    <br/>
                        <br/>
                        <br/>
        <h4 className="title-light" ><FormattedMessage id="home13" defaultMessage="LA LUMIÈRE" /></h4>
        <h3 className="title"><FormattedMessage id="home23" defaultMessage="POUR LES HÔPITAUX" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home33" defaultMessage="La bonne lumière crée une atmosphère de bien-être pour les patients, tout en permettant aux médecins et au personnel soignant de se concentrer sur leur travail." /> </h6>
                    </div>
                </div>
            </div>
  <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner8} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                    <br/>
                        <br/>
        <h4 className="title-light" ><FormattedMessage id="home14" defaultMessage="ILLUMINER" /></h4>
        <h3 className="title"><FormattedMessage id="home24" defaultMessage="VOTRE SÉJOUR" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home34" defaultMessage="L’éclairage que ce soit d’un hôtel ou d’un restaurant, Les espaces doivent être accueillants et attrayants, et doit inciter les visiteurs à venir et revenir encore. " /> </h6>
                    </div>
                </div>
            </div>
  <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner9} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                    <br/>
                        <br/>
                        <br/>
        <h4 className="title-light" ><FormattedMessage id="home15" defaultMessage="ÉCLAIRER" /></h4>
        <h3 className="title"><FormattedMessage id="home25" defaultMessage="POUR MIEUX ETUDIER" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home35" defaultMessage="L’éclairage apporte des solutions: une meilleure performance des élèves, une réelle réduction des consommations d’énergie et un entretien simplifié." /></h6>
                    </div>
                </div>
            </div>
  <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner10} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                    <br/>
                        <br/>
                        <br/>
        <h4 className="title-light" ><FormattedMessage id="home16" defaultMessage="L’ÉCLAIRAGE" /></h4>
        <h3 className="title"><FormattedMessage id="home26" defaultMessage="DE VOS INDUSTRIES" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home36" defaultMessage="Le secteur industriel englobe des activités différentes, pour chacune, il existe des solutions d’éclairage spécifiques. Il est indispensable de bien définir l'éclairage juste." /> </h6>
                    </div>
                </div>
            </div>
  <div className="col-md-6">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner11} alt="" className="myhome" />
                    </div>
                    <div className="banner-content banner-content-two" >
                    <br/>
                        <br/>
                        <br/>
        <h4 className="title-light" ><FormattedMessage id="home17" defaultMessage="ILLUMINER" /></h4>
        <h3 className="title"><FormattedMessage id="home27" defaultMessage="VOTRE HISTOIRE" /></h3>
                      <h6 className="col-md-5"><FormattedMessage id="home37" defaultMessage="Qu’il s’agisse de susciter l’impatience à l’arrivée, de communiquer une intensité dramatique ou d’inviter à la contemplation, l’éclairage joue un rôle clé dans tout l’espace d’exposition" /> </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</div>




      );
    };
    
    export default Home;