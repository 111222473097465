import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Content from "./components/content/Content";
// import "./App.css";
const App = () => {

  return (
    <Router>
      <div className="App wrapper">
        <Content />
      </div>
    </Router>
  );
};

export default App;
