import React, {useState} from "react";
import { IntlProvider } from "react-intl";
import English from "../languages/en-US.json";
import Frensh from "../languages/fr-CA.json";

export const Context = React.createContext();

// const local = navigator.language;

localStorage.setItem("local", navigator.language);

let lang;
if(localStorage.getItem("local") === "en-US"){
    lang= English
}else{
    lang= Frensh
}

const Wraper = (props) =>{
    const[locale,setLocale] = useState(localStorage.getItem("local"));
    const[messages,setMessages] = useState(lang);

    function selectLang(e){
        // const newLocale = e.target.value;
        localStorage.setItem("newLocale", e.target.value);
        setLocale(localStorage.getItem("newLocale"));
        if(localStorage.getItem("newLocale") === "en-US"){
            setMessages(English);
        } else{
            setMessages(Frensh);
        }
    }

    return(
        <Context.Provider value={{locale,selectLang}}>
            <IntlProvider locale={locale} messages={messages}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default Wraper;