import React, {useState, useEffect, useCallback} from 'react';
import { Button, Col, Form, FormGroup, Row, Input, Container } from 'reactstrap';
import BaseSelect from 'react-select';
import axios from "axios";
import DescCodPDF from './DescCodPDF.js';
import GeneratorFormRails from './GeneratorFormRails.js';
import FixRequiredSelect from './FixRequiredSelect.js';
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { faFileDownload, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GeneratorForm = (props) => {

    const [finitionInitial, setFinitionInitial] = useState('');
    const [finitionFieldData, setfinitionFieldData] = useState('');
    const [Couleur, setCouleur] = useState('');
    const [Driver, setDriver] = useState('');
    const [IP, setIP] = useState('');
    const [Douille, setDouille] = useState('');
    const [CRI, setCRI] = useState('');
    const [Angle, setAngle] = useState('');
    const [GGraph, setGGraph] = useState('');
    const [Puissance, setPuissance] = useState('');
    const [finitionField, setFinitionField] = useState('');
    const [pdf, setPdf] = useState('');
    const [douille, setdouille] = useState('');
    const [generate, setGenerate] = useState('');
    const [FluxP, setFluxP] = useState('');
    const [color, setColor] = useState('');
    const [driver, setdriver] = useState('');
    const [angle, setangle] = useState('');
    const [GraphG, setGraphG] = useState('');
    const [puissance, setpuissance] = useState('');
    const [codeProd, setcodeProd] = useState('');
    const [Description, setDescription] = useState('');
    const [typeCat, settypeCat] = useState('');
    const [nbrLed, setnbrLed] = useState('');
    const [semi, setsemi] = useState('');
    const [fluxData, setfluxData] = useState([]);
    const history = useHistory();
    const customStyles = { control: (base, state) => ({ ...base, background: " white", color: "black", height: 60, textAlign: "center", fontSize: 14, border: "1px solid black", cursor: "pointer" }) }; 

const ColorChange = (e) =>{ setCouleur(e.value); }
const DriverChange = (e) =>{ setDriver(e.value); }
const IPChange = (e) =>{ setIP(e.value); }
const CriChange = (e) =>{ setCRI(e.value); }
const AngleChange = (e) =>{ setAngle(e.value); }
const AngleGChange = (e) =>{ setGGraph(e.value); }
const PuissanceChange = (e) =>{ setPuissance(e.value); }
const DouilleChange = (e) =>{ setDouille(e.value); }
const FinitionChange = (e) =>{ setFinitionField(e.value); }


const come = useCallback(() =>{
    if(props.PuissanceT !== "" && props.PuissanceT !== null){ setPuissance(props.PuissanceT); }
    if(props.DriverT !== "" && props.DriverT !== null){ setDriver(props.DriverT); }
    if(props.AngleT !== "" && props.AngleT !== null){ setAngle(props.AngleT); }
    if(props.GGraphT !== "" && props.GGraphT !== null){setGGraph(props.GGraphT);}
    if(props.CRIT !== "" && props.CRIT !== null){setCRI(props.CRIT);}
    if(props.Ip !== "" && props.Ip !== null){setIP(props.Ip);}
    if(props.ColorT !== "" && props.ColorT !== null){setCouleur(props.ColorT);}
    if(props.DouilleT !== "" && props.DouilleT !== null){setDouille(props.DouilleT);}
    if(props.data.length !== 0){
        if(props.data.nomProduit.includes("SEMI")){
        setsemi("semi")}
    }
},[props.AngleT, props.CRIT, props.ColorT, props.DriverT, props.GGraphT, props.PuissanceT, props.DouilleT,props.Ip,props.data])

const onColorClick = useCallback(() =>{
    for (const dataObj of props.puissance) {
        if(dataObj.puissanceName === Puissance){ setnbrLed(dataObj.nbrLed)
            axios.get("http://localhost:8080/getFluxByPuissance/"+dataObj.idpuissance)
            .then(response => response.data).then((data)=> { 
                setfluxData(data);   
        }) } } },[props.puissance, Puissance]) 

const findFlux = useCallback(() =>{
    var found = ""
    if(fluxData.length === 1){
        for (const dataObj of fluxData) {
            let sou = dataObj.fluxName;sou = sou.replace(/[^0-9.]/g, ''); 
            sou = sou +" LM"; found= sou;
        }  } 
    if(fluxData.length === 2){ var vlueLM = []
        for (const dataObj of fluxData) {
            let sou = dataObj.fluxName; sou = sou.replace(/[^0-9.]/g, ''); vlueLM = vlueLM.concat(sou); vlueLM = vlueLM.sort();
            if(Couleur === "2700K"){ let first = vlueLM[0] + " LM"; found= first }
            if(Couleur === "3000K"){ var colorL = []; 
                for (const dataObjC of props.color) { colorL = colorL.concat(dataObjC.colorName); }
                if(colorL.includes("2700K")){ let first = vlueLM[1] + " LM"; found= first }
                if(colorL.includes("4000K")){ let first = vlueLM[0] + " LM"; found= first } }
            if(Couleur === "4000K"){ let first = vlueLM[1] + " LM"; found= first} } }
    if(fluxData.length === 3){ var vlueLM3 = []
        for (const dataObj of fluxData) {
            let sou = dataObj.fluxName; sou = sou.replace(/[^0-9.]/g, '');
            vlueLM3 = vlueLM3.concat(sou); vlueLM3 = vlueLM3.sort();
            if(Couleur === "2700K"){ let first = vlueLM3[0] + " LM"; found= first }
            if(Couleur === "3000K"){ let first = vlueLM3[1] + " LM"; found= first }
            if(Couleur === "4000K"){ let first = vlueLM3[2] + " LM"; found= first}
} } 
        setFluxP(found); 
},[Couleur, fluxData, props.color])

useEffect(() =>{ 
    come()
}, [come]);

useEffect(() =>{ 
    if(props.puissance.length !== 0 && Puissance !== ""){ 
        onColorClick();
    }
}, [onColorClick, props.puissance.length, Puissance]);


useEffect(() =>{ 
    if(fluxData.length !== 0 && Couleur !== ""){ 
    findFlux();
    }
}, [findFlux, fluxData, Couleur]);


const submitAll = (event) =>{
    event.preventDefault();
    setPdf("pdf");setGenerate("g"); settypeCat(props.data.typecategorie.nomType)
      var nom =[]; var name= ""; var formt =[]; var fini =[]; var fin = ""; var tcolor =[]; var c1 =""; 
      var c2= ""; var clr=""; var form =""; var taille =""; var puis = ""; var crt =[]; var cr =""; var drivert =[]; var dr = "";
      var anglet =[]; var a1 =""; var a2 =""; var a3 = ""; var a4 =""; var a5 =""; var a6 = ""; var a7 =""; var a8 =""; 
      var ang =""; var angletG =[]; var aG1 =""; var aG2 =""; var aG3 = ""; var aG4 =""; var aG5 =""; var aG6 = ""; var aG7 ="";
      var aG8 =""; var angGG =""; var productName = props.data.nomProduit; var productPuissance = ""; var productAngle= ""; 
     var productFinition = ""; var productColor = ""; var productDriver = "";
      var productCri = "";  
    //   var productAngleG= ""; 
      
      nom = props.data.nomProduit.split("");
      name= nom[0]+nom[1]+nom[2]+"-";
      crt = CRI.replace(/[^0-9.xX,]/g, '').split("");
      if(crt[0] == null){ cr="" }else{ cr = crt[0] }
      if(finitionField === "Autre choix"){ fini = finitionInitial.split("");
        if(fini[0] == null){ fin="" }else{ fin = fini[0] } }
      if(finitionField !== "Autre choix"){ fini = finitionField.split("");
        if(fini[0] == null){ fin="" }else{ fin = fini[0] } }
    if(finitionField === "Autre choix"){ setfinitionFieldData(finitionInitial) }
    if(finitionField !== "Autre choix"){ setfinitionFieldData(finitionField) }
    if(Couleur === "RGBW"){ clr = "CB" }
    if(Couleur === "RGB"){ clr = "C" }
    if(Couleur === "2700K" || Couleur === "3000K" || Couleur === "4000K"){
      tcolor = Couleur.split("");
      if(tcolor[0] == null){ c1="" }else{ c1 = tcolor[0] } if(tcolor[1] == null){ c2="" }else{ c2 = tcolor[1] }
      clr = c1+c2
    }
      anglet = Angle.split("");
      if(anglet[0] == null){ a1="" }else{ a1 = anglet[0] } if(anglet[1] == null){ a2="" }else{ a2 = anglet[1] }
      if(anglet[2] == null){ a3="" }else{ a3 = anglet[2] } if(anglet[3] == null){ a4="" }else{ a4 = anglet[3] }
      if(anglet[4] == null){ a5="" }else{ a5 = anglet[4] } if(anglet[5] == null){ a6="" }else{ a6 = anglet[5] }
      if(anglet[6] == null){ a7="" }else{ a7 = anglet[6] } if(anglet[7] == null){ a8="" }else{ a8 = anglet[7] }
      ang = a1+a2+a3+a4+a5+a6+a7+a8;
      angletG = GGraph.split("");
      if(angletG[0] == null){ aG1="" }else{ aG1 = angletG[0] } if(angletG[1] == null){ aG2="" }else{ aG2 = angletG[1] }
      if(angletG[2] == null){ aG3="" }else{ aG3 = angletG[2] } if(angletG[3] == null){ aG4="" }else{ aG4 = angletG[3] }
      if(angletG[4] == null){ aG5="" }else{ aG5 = angletG[4] } if(angletG[5] == null){ aG6="" }else{ aG6 = angletG[5] }
      if(angletG[6] == null){ aG7="" }else{ aG7 = angletG[6] } if(angletG[7] == null){ aG8="" }else{ aG8 = angletG[7] }
      angGG = aG1+aG2+aG3+aG4+aG5+aG6+aG7+aG8;

    drivert= Driver;
    if(drivert === "DMX"){ dr="DX" }
    if(drivert === "Dimmable" || drivert === "1-10V" || drivert === "0-10V"){ dr="D" }
    if(drivert === "DALI" ){ dr="DA" } if(drivert==="ON/OFF"){ dr=""; }
    if(props.data.form === null || props.data.form === "undefined" || props.data.form === "NULL" || props.data.form === ""){ form =""; }else{ formt = props.data.form.split(""); form = formt[0]+"-"; }
    if(props.data.taille === null || props.data.taille === "undefined" || props.data.taille === "NULL" || props.data.taille === "" ){ taille =""; }else{
       if(props.data.taille === "MAXI" || props.data.nomProduit.includes('BEAMLINE MICRO')){
        taille = "M-"; } if(props.data.taille === "S" || props.data.taille === "S1" || props.data.taille === "S2" || props.data.taille === "M" || props.data.taille === "L"){
        taille = props.data.taille+"-"; } if(props.data.taille === "MIDI"){ taille = "MID-"; } if(props.data.taille === "MINI"){ taille = "MIN-"; }
    }
    if(props.data.nomProduit.includes('BEAMLINE MICRO')){ taille = "M-"; }
    if(props.data.nomProduit.includes('BEAMLINE POWER')) {  form = "P-"; }
    if(props.data.nomProduit.includes('ALVO MICRO')){ taille = "MIC-"; }
    if (props.data.nomProduit.includes('ALVO POWER I')) {  form = "PI-"; }
    if (props.data.nomProduit.includes('ALVO POWER II')) {  form = "PII-"; }
    if (props.data.nomProduit.includes('BEAMLINE SLIM')) {  taille = "S-"; }
    if(props.data.nomProduit.includes('LONGO')) {  form = "L-"; }
    if(props.data.nomProduit.includes('ORIENTABLE')) {  form = "O-"; }
    if(props.data.nomProduit.includes('ARCHILINE ORIENTABLE')) {  form = "OR-"; }
    if(props.data.nomProduit.includes('ARCHILINE OPAQUE')) {  form = "OP-"; }
    if(props.data.nomProduit.includes('FONTE SPOT')) {  form = "S-"; }
    if(props.data.nomProduit.includes('FONTE PAREDE')) {  form = "P-"; } 
    if(props.data.nomProduit.includes('TRI-PROOF ECO')) {  form = "ECO-"; }

    if(Couleur !== "undefined"){ setColor(Couleur) }
    if(Driver !== "undefined"){ setdriver(Driver) } 
    if(Angle !== "undefined"){ setangle(Angle) }
    if(GGraph !== "undefined"){ setGraphG(GGraph) }
    if(Puissance !== "undefined"){ setpuissance(Puissance) }
    if(Douille !== "undefined"){ setdouille(Douille) }
    if(Couleur != null){ productColor= Couleur; }
    if(Driver != null){ productDriver= Driver; }
    if(Angle != null){ productAngle= Angle; }
    // if(GGraph != null){ productAngleG= GGraph; }
    if(Puissance != null){ productPuissance= Puissance; }
    if(CRI != null){ productCri= productCri= CRI;; }
    if(finitionField != null){ productFinition= finitionField; }
    ang= ang.replace(/[^0-9.xX,]/g, ''); angGG=angGG.replace('°', '');
    puis = Puissance;
    puis= puis.replace(/[^0-9.Xx,]/g, ''); 
    var doui = Douille;
    var IPproduct= "";
    if(IP !== null && IP !== "" && IP !== "NULL" && IP !== "undefined"){
        IPproduct = "IP"+IP;
    }
    var IKproduct= "";
    if (props.data.ik !==null && props.data.ik !== "" && props.data.ik !== "NULL" && props.data.ik !== "undefined"){
        IKproduct= "IK"+props.data.ik;
    }
    if(productFinition==="Autre choix"){
        productFinition=""
    }
    if(props.puissance.length !== 0 && props.douille.length !== 0){
        doui = Douille;
        puis = "";
    }
    if(props.data.nomProduit.includes("SEMI")){
        angGG=""
    }
 setcodeProd(name+form+taille+fin+puis+doui+cr+clr+ang+angGG+dr)
 setDescription(productName+ "  " +productFinition+ "  " +productPuissance+ " " +doui+ " " +productCri+ "  " +productColor+ "  " +productAngle+ "  " +productDriver+ " "+IPproduct+ " "+IKproduct)
}

var datapuissance=[];
props.puissance.map((p)=>( datapuissance = datapuissance.concat([{ value: p.puissanceName, label: p.puissanceName }]) ))
var datadouille=[];
props.douille.map((p)=>( datadouille = datadouille.concat([{ value: p.name, label: p.name }]) ))
var datacri=[];
props.cri.map((c)=>( datacri = datacri.concat([{ value: c.criName, label: c.criName }]) ))
var dataColor=[];
props.color.map((c)=>( dataColor = dataColor.concat([{ value: c.colorName, label: c.colorName }]) ))
var datadriver=[];
props.driver.map((c)=>( datadriver = datadriver.concat([{ value: c.driverName, label: c.driverName }]) ))
var dataangle=[];
props.angle.map((c)=>( dataangle = dataangle.concat([{ value: c.angleName, label: c.angleName }]) ))
var dataangleG=[];
props.angleGraph.map((c)=>( dataangleG = dataangleG.concat([{ value: c.angleGraphName, label: c.angleGraphName }]) ))
var dataFinition = [{value: props.data.finition , label: props.data.finition},{value: "Autre choix" , label: "Autre choix"}];
    dataangle = dataangle.filter((item) => item.value !== ""); dataangleG = dataangleG.filter((item) => item.value !== "");
    dataangleG = dataangleG.filter((item) => item.value !== "undefined"); dataangleG = dataangleG.filter((item) => item.value !== null);
    datapuissance = datapuissance.filter((item) => item.value !== ""); dataangle = dataangle.filter((item) => item.value !== null);
    datapuissance = datapuissance.filter((item) => item.value !== null); dataColor = dataColor.filter((item) => item.value !== null);
    datacri = datacri.filter((item) => item.value !== null);dataFinition = dataFinition.filter((item) => item.value !== null); 
    datadriver = datadriver.filter((item) => item.value !== null); dataFinition = dataFinition.filter((item) => item.value !== "undefined");
    dataFinition = dataFinition.filter((item) => item.value !== "");  datadouille = datadouille.filter((item) => item.value !== "");
    datadouille = datadouille.filter((item) => item.value !== null);
var ipdata=[];
props.ip.map((p)=>( ipdata = ipdata.concat([{ value: p.nombre, label: p.nombre }]) ))
ipdata = ipdata.filter((item) => item.value !== ""); ipdata = ipdata.filter((item) => item.value !== null);

var angleT=""; 
var angleGT=""; 
if(dataangle.length !== 0){ angleT="angle"; } if(dataangleG.length !== 0){ angleGT="angleG"; }


const Select = props => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
    />
  );

  const Ies = () =>{

    if(localStorage.getItem("username") === null && localStorage.getItem("email") === null  ){
        history.push("/Register and Login");
    }
    
    if(localStorage.getItem("email") !== null && localStorage.getItem("username") !== null){
        window.open(props.data.ies);
    }

}


const intl = useIntl();
const placeholderc = intl.formatMessage({ id: "color" });
const placeholderp = intl.formatMessage({ id: "power" });
const placeholdera = intl.formatMessage({ id: "angle" });
// const placeholderm = intl.formatMessage({ id: "message" });    

  return (
    <Container> 
    <div className="calculate-shipping">
    <h4 style={{marginTop:30,marginBottom:50,textTransform:'uppercase'}}><FormattedMessage id="configtitle" defaultMessage="Configurateur De Produit" /></h4></div>
    {props.data.rails !== "RAILS"?
        <div>
            <Form onSubmit={submitAll} id="fornId">
                <Row style={{paddingLeft:0,paddingRight:0}}>
                    <Col lg="3" md="4" sm="12">
                        <FormGroup style={{pointerEvents: dataFinition.length === 0? "none": "all"}}>
                        <Select
                            placeholder="Finition"
                            value= {dataFinition.find(objpf => objpf.value === finitionField)}
                            options={dataFinition} 
                            onChange={FinitionChange} 
                            styles={customStyles}
                            required = {dataFinition.length === 0? "": true}
                        />
                    </FormGroup>
                    </Col>
                    <Col lg="3" md="4" sm="12">
                    {datadouille.length !== 0?
                        <FormGroup style={{pointerEvents: datadouille.length <= 1? "none": "all"}}>
                            <Select
                                placeholder="Douille"
                                value= {datadouille.find(objp => objp.value === Douille)}
                                options={datadouille} 
                                onChange={DouilleChange} 
                                styles={customStyles}
                                required = {datadouille.length <= 1? "": true}
                            />
                        </FormGroup>
                        :
                        <FormGroup style={{pointerEvents: datapuissance.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholderp}
                                value= {datapuissance.find(objp => objp.value === Puissance)}
                                options={datapuissance} 
                                onChange={PuissanceChange} 
                                styles={customStyles}
                                required = {datapuissance.length <= 1? "": true}
                            />
                        </FormGroup>
                    }
                    </Col>
                    <Col lg="3" md="4" sm="12">
                        <FormGroup style={{pointerEvents: datacri.length <= 1? "none": "all"}}>
                            <Select
                                placeholder="CRI"
                                value= {datacri.find(objcri => objcri.value === CRI)}
                                options={datacri} 
                                onChange={CriChange} 
                                styles={customStyles}
                                required = {datacri.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3" md="4" sm="12" style={{display: dataColor.length === 0? "none": ""}}>
                        <FormGroup style={{pointerEvents: dataColor.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholderc}
                                value= {dataColor.find(objcolor => objcolor.value === Couleur)}
                                options={dataColor} 
                                onChange={ColorChange}
                                styles={customStyles}
                                required = {dataColor.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    {angleT === "angle"? 
                        <Col lg="3" md="4" sm="12">
                            <FormGroup style={{pointerEvents: dataangle.length <= 1? "none": "all"}}>
                                <Select
                                    placeholder={placeholdera}
                                    value= {dataangle.find(objangle => objangle.value === Angle)}
                                    options={dataangle}
                                    onChange={AngleChange} 
                                    styles={customStyles}
                                    required = {dataangle.length <= 1? "": true}
                                />
                            </FormGroup>
                        </Col>
                    : "" }
                     {angleGT === "angleG"? 
                        <Col lg="3" md="4" sm="12" style={{display: semi === "semi" ? "none": ""}}>
                            <FormGroup style={{pointerEvents: dataangleG.length <= 1? "none": "all"}} >
                                <Select
                                    placeholder={placeholdera}
                                    value= {dataangleG.find(objangleGG => objangleGG.value === GGraph)}
                                    options={dataangleG}
                                    onChange={AngleGChange} 
                                    styles={customStyles}
                                    required = {dataangleG.length <= 1? "": true}
                                />
                            </FormGroup>
                        </Col>
                        : "" }
                     {dataangleG.length=== 0 && dataangle.length === 0? 
                    <Col lg="3" md="4" sm="12">
                        <FormGroup style={{pointerEvents: dataangleG.length <= 1 && dataangle.length <= 1? "none": "all"}} >
                            <Select
                                placeholder={placeholdera}
                                value= {dataangle.find(objangle => objangle.value === Angle)}
                                options={dataangle}
                                onChange={AngleChange} 
                                styles={customStyles}
                                required = {dataangleG.length <= 1 && dataangle.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    : "" }
                    <Col lg="3" md="4" sm="12">
                        <FormGroup style={{pointerEvents: datadriver.length <= 1? "none": "all"}} >
                            <Select
                                placeholder="Driver"
                                value= {datadriver.find(objdriver => objdriver.value === Driver)}
                                options={datadriver} 
                                onChange={DriverChange} 
                                styles={customStyles}
                                required = {datadriver.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3" md="4" sm="12">
                        <FormGroup style={{pointerEvents: ipdata.length <= 1? "none": "all"}} >
                            <Select
                                placeholder="IP"
                                value= {ipdata.find(objdriver => objdriver.value === IP)}
                                options={ipdata} 
                                onChange={IPChange} 
                                styles={customStyles}
                                required = {ipdata.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3" md="4" sm="12" style={{display:finitionField === "Autre choix"? "" : "none"}}>
                        <FormGroup >
                        <Input type="text" name="finitionInitial" value={finitionInitial} onChange={e => setFinitionInitial(e.target.value)}  autoComplete="off" placeholder="Finition" style={{borderRadius:"none",borderTop:"none",borderRight:"none",borderLeft:"none",marginTop:10,height:50,pointerEvents:finitionField === "Autre choix"? "all" : "none"}}  />                
                        </FormGroup>
                    </Col>
                </Row>
                <div className="row" style={{marginTop:50}}>
                    <div className="col-md-4 col-12 mb-25 flexme">
                        <div className="cart-summary">
                            <div className="cart-summary-button">
                                <Button type="submit" className="btn"><FontAwesomeIcon icon={faTools} /> <FormattedMessage id="config" defaultMessage="Configuration" /></Button>
                            </div>
                        </div>
                        {generate === "g" ?
                            <DescCodPDF douille={douille} doui={props.douille} ip={props.ip} certification={props.certification} idProduit={props.data.idProduit} ies={props.data.ies} cod={codeProd} desc={Description} pdfS={pdf} yes={props.data} angleG={props.angle} angleGraphG={props.angleGraph} puis={props.puissance} finitionField={finitionFieldData} color={color} driverc={driver} angle={angle} GraphG={GraphG} puissance={puissance} sous={typeCat} codeProd={codeProd} cri={CRI} flux={FluxP} nbr={nbrLed} />
                        :  "" }
                        <div className="cart-summary" style={{pointerEvents:props.data.ies !== null && props.data.ies !== "" && props.data.ies !== "NULL"? "": "none"}}>
                            <div className="cart-summary-button">
                                <button className="btn" onClick={Ies}><FontAwesomeIcon icon={faFileDownload} /> <FormattedMessage id="ies" defaultMessage="Fichier IES" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mb-25">
                        <div className="cart-summary">
                            <div className="cart-summary-wrap">
                                <h3>&#x2023;<b> <FormattedMessage id="code" defaultMessage="Code Produit" />:</b></h3><h3 style={{paddingLeft:40}}>{codeProd}</h3>
                                <h3>&#x2023;<b> <FormattedMessage id="desc" defaultMessage="Désignation du produit" />:</b></h3><h3 style={{paddingLeft:40}}>{Description}</h3>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <Row className="codeGenerate">
                    <Col md="12" style={{textAlign:"center",marginTop:30}}>
                        <div style={{width:"100%",textAlign:"center"}}>
                            <Button type="submit" className="btn" >Envoyer</Button>
                        </div>
                    </Col>
                </Row>
                {generate === "g" ?
                <DescCodPDF douille={douille} doui={props.douille} ip={props.ip} certification={props.certification} idProduit={props.data.idProduit} ies={props.data.ies} cod={codeProd} desc={Description} pdfS={pdf} yes={props.data} angleG={props.angle} angleGraphG={props.angleGraph} puis={props.puissance} finitionField={finitionFieldData} color={color} driverc={driver} angle={angle} GraphG={GraphG} puissance={puissance} sous={typeCat} codeProd={codeProd} cri={CRI} flux={FluxP} nbr={nbrLed} />
                :  "" }   */}
            </Form>
        </div>
        :
        <GeneratorFormRails typeName={props.typeName} ip={props.ip} Ip={props.Ip} longeur={props.longeur} driver={props.driver} coin={props.coin} data={props.data} yes={props.data} certification={props.certification} accessoire={props.accessoire} sous={typeCat} idProduit={props.data.idProduit} />
        }
    </Container>
  );

}
export default  React.memo(GeneratorForm)

    