import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import  logo  from "../../assets/images/logoPDF.png";

const styles = StyleSheet.create({
    page: {
        paddingTop: 25,
        paddingBottom: 25,
        paddingHorizontal: 25,
        fontFamily: "Montserrat",
    },
    title: {
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'Oswald'
      },
      image: {
        marginVertical: 15,
        marginHorizontal: 130,
        height:150,
      },
      border: {
        textDecoration: "none",
          padding: "1px",
          paddingTop:0,
          color: "#4a4a4a",
          backgroundColor: "grey",
          position: 'absolute',
          bottom: 60,
          left: 0,
          right: 0,
          flexGrow: 1
      },
      table: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: 0,
        paddingRight: 0,
      },
      tr: {
        width: '43%',
        marginRight: 0,
        paddingRight: 0,
        position: "relative",
        right: 0,
      },
      td:{
        borderBottom: "1 solid black",
        paddingBottom: 4,
        fontSize: 7,
        fontWeight: 3,
        marginBottom: 7,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 1
      },
      tr1: {
        width: '57%',
        marginRight: 0,
        paddingRight: 0,
      },
      HeaderTop:{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position:"absolute",
        top: 20,
        right: 20,
        left: 20,
        border: "1 solid black",
        borderLeft: "none",
      },
      type: {
        width: '34%',
        textAlign: "center",
        paddingRight: 0,
        marginRight: 0,
        paddingLeft: 0,
        marginLeft: 0,
      },
      logo: {
        width: '66%',
        paddingLeft: 0,
        marginLeft: 0,
        paddingTop: 2,
        paddingBottom: 2,
      },
      werdell:{
        height: 35,
        width: 150,
        paddingLeft: 0,
        marginLeft: -21,
        
      },
      typeText: {
        fontSize: 13,
        textTransform: "uppercase",
        color: "white",
        paddingTop: 12,
        paddingBottom: 12,
        height: "100%",
        backgroundColor: "black",
        textAlign: "center",
        fontFamily: "MontserratBold",
      },
      nomP: {
        fontSize: 22,
        color: "#ee6b24",
        fontFamily: "MontserratBold"
      },
      t:{
        width: '64%',
        fontSize: 7,
      },
      t1: {
        width: '36%',
        textAlign: "left",
        fontSize: 6,
        paddingRight: 2,
        fontFamily: "MontserratThin",
        color: "black"
      },
      h : {
        color: "#ee6b24",
        fontSize: 10,
        paddingBottom: 10,
        textTransform: "uppercase",
      },
      h1 : {
        color: "#ee6b24",
        fontSize: 10,
        paddingBottom: 13,
        marginLeft: 18,
        textTransform: "uppercase",
      },
      tech: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // marginTop: 10,
        marginLeft: 15,
        borderBottom: "1 solid black",
      },
      tech1: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 15,
        borderBottom: "1 solid black",
        height: 42,
        paddingTop: 4
      },
      ipik : {
        width: "18px",
        height: "19px",
        marginRight: 1,
      },
      ipikTest: {
       width: 0,
       height: 0,
       padding: 0,
       margin: 0,
       left: 0,
       right: 0 
      },
      ipik1 : {
        width: "18px",
        height: "19px",
        marginRight: 1,
        fontSize: 6,
        textAlign: "center",
        paddingLeft:4,
        paddingRight:4,
        paddingTop: 3,
        borderBottomRightRadius: 5,
        border: "1 solid black",
      },
      ipikINL: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // marginTop: 10,
        marginLeft: 15,
        position: "absolute",
        bottom: "3%"
      },
      te : {
        width: "18%",
        fontSize: 7,
        textAlign: "center",
        paddingBottom: 4,
      },
      code : {
        fontSize: 14,
        textTransform: "uppercase",
        marginTop: 0,
        color: "#000000",
        fontWeight: "normal",
        fontFamily: "Montserrat",
      },
      TitleHeader: {
        position: "absolute",
        top: "15%",
        marginLeft: 25,
        fontFamily: "MontserratBold"
      },
      disp: {
        display: "none",
      },
      te1 : {
        width: "18%",
        fontSize: 6.5,
        textAlign: "center",
        paddingBottom: 10,
        paddingTop:10,
        fontFamily: "MontserratThin",
        color: "black"
      },
      puisImg: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        bottom: 290,
        left: 25
      },
      puisImgACC: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        bottom: 275,
        right: -110
      },
      contPuis: {
        width: "65%"
      },
      contPuis1: {
        width: "50%"
      },
      footer: {
        position: "absolute",
        bottom: 0,
        textAlign: "center",
        borderTop: "1 solid lightgray",
        width: "100%",
        marginLeft: 20,
        marginRight: 20,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      footerL: {
        width: "50%",
        textAlign: "left",
        fontSize: 5.3,
      },
      footerR: {
        width: "50%",
        textAlign: "right",
        fontSize: 6,
      },
      footerText: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      tableG: {
        position: "absolute",
        bottom: 53,
        marginLeft: 25,
        marginRight: 0,
        paddingRight: 0,
        width: "100%",
      },
      ImageGlobal: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      ImageCont: {
        width: "44%",
        left: 25,
        marginTop: 10
      },
      ImageCont2: {
        width: "23%",
        textAlign: "center",
      },
      ImageGlobalFix: {
        position: "absolute",
        top: "19%",
      },
      ImageCont1: {
        width: "33%",
        textAlign: "center",
      },
      anglePositionTop: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        top: 255,
        right: 25
      },
      anglePos1: {
        width: "76%"
      },
      anglePos: {
        width: "24%",
      }, 
      anglePosmin: {
        width: "90%"
      },
      AnglePosMin1 : {
        width: "10%"
      },
      no : {
        width: "0%"
      }
  });

    const PROFilesPDF = (props) => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View  style={styles.HeaderTop}>
              <View style={styles.logo}>
                  <Image src={logo} alt="" style={styles.werdell} />
              </View>
              <View style={styles.type}>
                  <Text style={styles.typeText}>{props.sous}</Text>
              </View>
          </View>
          <View style={styles.TitleHeader}>
              <Text style={styles.nomP}>{props.yes.nomProduit}</Text>
              <Text style={styles.code}>{props.codeProd}</Text>
          </View>
          <View style={styles.ImageGlobalFix}>
            <View style={styles.ImageGlobal}>
              <View style={styles.ImageCont}>
                {props.yes.profilePicPath !== null && props.yes.profilePicPath !== "" ?
                <Image
                  src={props.yes.profilePicPath} alt=""
                />
                :""}
              </View>
              <View style={styles.ImageCont2}>
              </View>
              <View style={styles.ImageCont1}>
              </View>
            </View>
          </View>
          <View style={styles.anglePositionTop} >
           <View style={styles.anglePos1} ></View>
              <View style={styles.anglePos} >
              {props.angleGraphG.map((a)=>(
                <View key={a.idangleGraph}>
                  {props.GraphG === a.angleGraphName?
                    <Image src={a.image} alt="" />
                      :
                    <Text></Text>}
                </View>
              ))}
            </View>
          </View>
          <View style={styles.anglePositionTop} >
            <View style={styles.anglePosmin} ></View>
              <View style={styles.AnglePosMin1}>
              {props.angleG.map((a)=>(
                <View key={a.idangle}>
                  {props.angle === a.angleName?
                    <Image src={a.image} alt="" />
                      :
                    <Text></Text>}
                </View>
              ))}
            </View>
          </View>
          <View style={styles.puisImg}>
              <View style={styles.contPuis}>
              {props.puis.map((a)=>(
                <View key={a.idpuissance}>
                  {props.puissanceImg === a.puissanceName?
                    <Image src={a.image} alt="" />
                      :
                    <Text></Text>}
                </View>
              ))}
            </View>
          </View>
          <View style={styles.puisImgACC}>
            <View style={styles.contPuis}>
            {props.yes.accessoire !== ""  && props.yes.accessoire !== "NULL"  && props.yes.accessoire !== null ?
              <Image src={props.yes.accessoire} alt="" /> 
              :
              <Text style={styles.disp}></Text>
              }
            </View>
          </View>
          <View style={styles.tableG} >
            <View style={styles.table}>
              <View style={styles.tr}>
                <View style={styles.td}>
                  <Text style={styles.h}>PARAMÈTRES GÉNÉRALES</Text>
                </View>
                {props.finitionField !== "" && props.finitionField !== null && props.finitionField !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Finition</Text>
                  <Text style={styles.t1}>{props.finitionField}</Text>
                </View>
                :
                <Text style={styles.disp}></Text>
               } 
               {props.yes.form !== "" && props.yes.form !== null && props.yes.form !== "undefined"   ?
                  <View style={styles.td}> 
                    <Text style={styles.t}> Fixation</Text>
                    <Text style={styles.t1}>{props.yes.form}</Text>
                  </View>
                 :
                 <Text style={styles.disp}></Text>
                } 
               {props.yes.sectionR !== "" && props.yes.sectionR !== null && props.yes.sectionR !== "undefined" ?
               <View style={styles.td}> 
                 <Text style={styles.t}> Section</Text>
                 <Text style={styles.t1}>{props.yes.sectionR}</Text>
               </View>
                :
                <Text style={styles.disp}></Text>
               } 
               {props.longeur !== "" && props.longeur !== null && props.longeur !== "undefined" ?
               <View style={styles.td}> 
                 <Text style={styles.t}> La longueur</Text>
                 <Text style={styles.t1}>{props.longeur}</Text>
               </View>
               :
               <Text style={styles.disp}></Text>
              }
              {props.yes.largeur !== "" && props.yes.largeur !== null && props.yes.largeur !== "undefined" ?
              <View style={styles.td}> 
                <Text style={styles.t}> La Largeur de bande</Text>
                <Text style={styles.t1}>{props.yes.largeur}</Text>
              </View>
              :
              <Text style={styles.disp}></Text>
             } 
              {props.yes.tension !== "" && props.yes.tension !== null && props.yes.tension !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Tension / Fréquence</Text>
                  <Text style={styles.t1}>{props.yes.tension}</Text>
                </View>
                 :
                 <Text style={styles.disp}></Text>
                } 
                {props.yes.ugr !== "" && props.yes.ugr !== null && props.yes.ugr !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Taux d’éblouissement unifié (UGR)</Text>
                  <Text style={styles.t1}>{props.yes.ugr}</Text>
                </View>
                 :
                 <Text style={styles.disp}></Text>
                } 
                {props.yes.sdcm !== "" && props.yes.sdcm !== null && props.yes.sdcm !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> déviation standard de couleur (SDCM)</Text>
                  <Text style={styles.t1}>{props.yes.sdcm}</Text>
                </View>
                :
                <Text style={styles.disp}></Text>
               } 
                {props.typeSource !== "" && props.typeSource !== null && props.typeSource !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Type de source</Text>
                  <Text style={styles.t1}>{props.typeSource}</Text>
                </View>
                :
                <Text style={styles.disp}></Text>
               } 
                {props.tempFonctionnement !== "" && props.tempFonctionnement !== null && props.tempFonctionnement !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Températures de fonctionnement</Text>
                  <Text style={styles.t1}>{props.yes.tempFonctionnement}</Text>
                </View>
                 :
                 <Text style={styles.disp}></Text>
                } 
                {props.yes.classProtection !== "" && props.yes.classProtection !== null && props.yes.classProtection !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Classe de protection</Text>
                  <Text style={styles.t1}>{props.yes.classProtection}</Text>
                </View>
                 :
                 <Text style={styles.disp}></Text>
                } 
                {props.dureeVie !== "" && props.dureeVie !== null && props.dureeVie !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Durée de vie</Text>
                  <Text style={styles.t1}>{props.yes.dureeVie}</Text>
                </View>
                :
                <Text style={styles.disp}></Text>
               } 
                {props.driverc !== "" && props.driverc !== null && props.driverc !== "undefined" ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Driver</Text>
                  <Text style={styles.t1}>{props.driverc}</Text>
                </View>
                  :
                  <Text style={styles.disp}></Text>
                 } 
                {props.yes.taille !== "" && props.yes.taille !== null && props.yes.taille !== "undefined" ?
                  <View style={styles.td}> 
                    <Text style={styles.t}> Taille</Text>
                    <Text style={styles.t1}>{props.yes.taille}</Text>
                  </View>
                 :
                 <Text style={styles.disp}></Text>
                }
                {props.yes.protecteurTension !== "" && props.yes.protecteurTension !== null && props.yes.protecteurTension !== "undefined"   ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Protecteur de surtensions</Text>
                  <Text style={styles.t1}>{props.yes.protecteurTension}</Text>
                </View>
                :
                 <Text style={styles.disp}></Text>
                }
                {props.yes.poids !== "" && props.yes.poids !== null && props.yes.poids !== "undefined"   ?
                <View style={styles.td}> 
                  <Text style={styles.t}> Poids net</Text>
                  <Text style={styles.t1}>{props.yes.poids}</Text>
                </View>
                :
                <View>
                 <Text style={styles.disp}></Text>
                </View>
                }
            </View>
            <View style={styles.tr1}>
              <Text style={styles.h1}>PARAMÈTRES TECHNIQUES</Text>
                <View style={styles.tech}> 
                  <Text style={styles.te}>Puissance</Text>
                  <Text style={styles.te}> Flux lumineux</Text>

                  {props.nbr !== null && props.nbr !== "" && props.nbr !== "undefined" && props.nbr !== "NULL"?
                    <Text style={styles.te}>Nombre de LED</Text>
                  :
                    <Text></Text>
                  } 
                  <Text style={styles.te}>Température de couleur</Text>
                  <Text style={styles.te}>CRI</Text>
                {props.yes.facteurPuissance !== null && props.yes.facteurPuissance !== "" && props.yes.facteurPuissance !== "undefined" && props.yes.facteurPuissance !== "NULL"?
                  <Text style={styles.te}>Facteur de puissance</Text>
                :
                  <Text></Text>
                }
                </View>
                <View style={styles.tech1}> 
                  <Text style={styles.te1}>{props.puissance}</Text>
                  {props.flux !== null && props.flux !== "" && props.flux !== "undefined"?
                    <Text style={styles.te1}>{props.flux}</Text>
                  :
                    <Text></Text> 
                   }
                  {props.nbr !== null && props.nbr !== "" && props.nbr !== "undefined" && props.nbr !== "NULL"?
                  <Text style={styles.te1}>{props.nbr}</Text>
                  :
                  <Text></Text>
                  }
                  <Text style={styles.te1}>{props.color}</Text>
                  <Text style={styles.te1}>{props.cri}</Text>
                  {props.yes.facteurPuissance !== null && props.yes.facteurPuissance !== "" && props.yes.facteurPuissance !== "undefined" && props.yes.facteurPuissance !== "NULL"?
                  <Text style={styles.te1}>{props.yes.facteurPuissance}</Text>
                  :
                  <Text></Text>
                  }
                </View>

                <View style={styles.ipikINL}>
                  {props.certification.map((a)=>(
                    <View key={a.idcertification}>
                        <Image src={a.image} alt="" style={styles.ipik} />
                    </View>
                  ))}
                  
                  {props.ip.map((a)=>(
                    <View key={a.idIp}> 
                      <Text style={styles.ipik1}> IP {a.nombre}</Text>
                    </View>
                   ))}

                  {props.yes.ik !== "" && props.yes.ik !== "undefined" && props.yes.ik !== null? 
                   <Text style={styles.ipik1}> IK {props.yes.ik} </Text>
                   : <Text style={styles.ipik}> </Text>} 
                   <Text style={props.certification.length <5?styles.ipik: styles.no }> </Text>
                   <Text style={props.certification.length <5?styles.ipik: styles.no }> </Text>
                   <Text style={props.certification.length <5?styles.ipik: styles.no }></Text>
                   <Text style={props.certification.length <5?styles.ipik: styles.no }></Text>
                </View>
              </View>
            </View>
          </View> 
      <View style={styles.footer}>
        <View style={styles.footerL}>
          <Text style={styles.footerText}>WERDELL LIGHTING </Text>
        </View>
        <View style={styles.footerR}>
          <Text style={styles.footerText}>info@werdell.com | www.werdell.com</Text>
        </View>
      </View>
      </Page>
      </Document>
    );
    
    Font.register({
      family: 'Montserrat',
      src: '//db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.ttf'
    });
    Font.register({
      family: 'MontserratThin',
      src: '//db.onlinewebfonts.com/t/029177df870cce2b384f0610a6e1f82a.ttf'
    });
    Font.register({
      family: 'MontserratBold',
      src: '//db.onlinewebfonts.com/t/07eae3dd22b2a99864228b51f4f918a5.ttf'
    });
    

    export default React.memo(PROFilesPDF);

    