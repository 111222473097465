import React, {Component} from "react";
import { Link } from "react-router-dom";

import banner1 from "../../assets/images/about/about-us-banner1.jpg";
import signature from "../../assets/images/about/about-us-signature.png";
import policy from "../../assets/images/icons/about-us-policy1.png";
import policy2 from "../../assets/images/icons/about-us-policy2.png";
import policy3 from "../../assets/images/icons/about-us-policy3.png";
import about1 from "../../assets/images/about/about-1.jpg";
import about2 from "../../assets/images/about/about-2.jpg";
import about3 from "../../assets/images/about/about-3.jpg";
import bg from "../../assets/images/bg/breadcrumb.jpg";
import { FormattedMessage } from "react-intl";

export default class About extends Component {

    render() {
        return (
            <div id="main-wrapper">
                <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h2><FormattedMessage id="about" defaultMessage="À Propos" /></h2>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
                                        <li><FormattedMessage id="about" defaultMessage="À Propos" /></li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-us-area section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="about-us-img-wrapper">
                                    <div className="about-us-image">
                                        <img src={banner1} alt="" />
                                    </div>
                                </div>
                                <div className="about-us-content">
                                    <h4><FormattedMessage id="abouth4" defaultMessage="Nous fabriquons fièrement tous nos produits en interne." /></h4>
                                    <p><FormattedMessage id="aboutdesc" defaultMessage="WERDELL LIGHTING fournit des luminaires innovants et primés à tous les secteurs d'activité, y compris les projets d'hôtellerie, de vente au détail, commerciaux, d'entreprise et résidentiels. nous continuons à nous appuyer sur des années de perspicacité et d'expérience de l'industrie, répondant avec succès aux demandes des clients avec notre large gamme de produits. une tradition de savoir-faire exceptionnel a positionné WERDELL LIGHTING comme l'un des principaux fabricants mondiaux d'éclairage." /></p>
                                    <div className="signeture-image">
                                        <img src={signature} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="feature-section section bg-gray-two pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
                    <div className="container">
                        <div className="row AboutUs">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-feature feature-style-two mb-30">
                                    <div className="feature-image">
                                        <img src={policy} className="img-fluid" alt="" />
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title"><FormattedMessage id="aboutinovationh4" defaultMessage="Innovation" /></h4>
                                        <p className="short-desc"><FormattedMessage id="aboutinovation" defaultMessage="Nous innovons sans cesse pour apporter des réponses adaptées à vos besoins grâce aux nouvelles technologies." /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-feature feature-style-two mb-30">
                                    <div className="feature-image">
                                        <img src={policy2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title"><FormattedMessage id="aboutqualityh4" defaultMessage="Qualité" /></h4>
                                        <p className="short-desc"><FormattedMessage id="aboutquality" defaultMessage="Tous nos produits sont certifiés par des organismes indépendants. Ils sont testés et font l’objet d’une veille permanente." /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-feature feature-style-two mb-30">
                                    <div className="feature-image">
                                        <img src={policy3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title"><FormattedMessage id="abouttechnologyh4" defaultMessage="Technologie" /></h4>
                                        <p className="short-desc"><FormattedMessage id="abouttechnology" defaultMessage="Fiabilité et robustesse permettent à nos produits de répondre aux évolutions des nouvelles technologies comme la LED" /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-section section  pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-60 pb-lg-40 pb-md-30 pb-sm-20 pb-xs-10">
                    <div className="container">
                        <div className="row AboutUs">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-service mb-30">
                                    <div className="service-img">
                                        <img src={about1} alt="" />
                                    </div>
                                    <div className="service-content">
                                        <h4 className="title"><FormattedMessage id="aboutlast1h4" defaultMessage="Valeurs concrètes qui font la différence" /></h4>
                                        <p style={{textAlign:"justify"}}><FormattedMessage id="aboutlast1" values={{ linebreak: <br /> }} defaultMessage="La vision de WERDELL LIGHTING dans le monde de l’éclairage se fonde sur des valeurs concrètes qui font la différence. {linebreak}{linebreak} Une philosophie qui relève le défi pour développer et réaliser des solutions immédiates et profondes, en harmonie avec l’architecture, la nature et les personnes." /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-service mb-30">
                                    <div className="service-img">
                                        <img src={about2} alt="" />
                                    </div>
                                    <div className="service-content">
                                        <h4 className="title"><FormattedMessage id="aboutlast2h4" defaultMessage="Solutions pour chaque catégorie de projet" /></h4>
                                        <p style={{textAlign:"justify"}}><FormattedMessage id="aboutlast2" values={{ linebreak: <br /> }} defaultMessage="WERDELL LIGHTING parvient à satisfaire toutes les demandes du Marché global, garantissant et livrant des appareils conçus pour tous les types d’habitat. {linebreak} {linebreak} Son vaste réseau de vente et ses sièges ont permis de devenir une référence en matière d’éclairage, parmi les plus connues et appréciées des professionnels." /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-service mb-30">
                                    <div className="service-img">
                                        <img src={about3} alt="" />
                                    </div>
                                    <div className="service-content">
                                        <h4 className="title"><FormattedMessage id="aboutlast3h4" defaultMessage="Durabilité, qualité et garantie" /></h4>
                                        <p style={{textAlign:"justify"}}><FormattedMessage id="aboutlast3" values={{ linebreak: <br /> }} defaultMessage="WERDELL LIGHTING continue à respecter l’environnement quels que soient les services de la société, bureaux, production, et entrepôt. {linebreak} {linebreak} La société consacre des ressources à la limitation de son impact environnemental dans ses activités grâce à des mesures en faveur du développement durable." /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      );
    };
    
}