import React, {useState, useEffect, useCallback} from 'react';
import { Button, Col, Form, FormGroup, Row, Input, Container } from 'reactstrap';
import BaseSelect from 'react-select';
import axios from "axios";
import ProfilesDescCodPDF from './ProfilesDescCodPDF.js';
import FixRequiredSelect from '../ConsultationProd/FixRequiredSelect.js';
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { faDownload, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProfilesGenerator = (props) => {

    const [finitionInitial, setFinitionInitial] = useState('');
    const [finitionFieldData, setfinitionFieldData] = useState('');
    const [Couleur, setCouleur] = useState('');
    const [Driver, setDriver] = useState('');
    const [IP, setIP] = useState('');
    const [CRI, setCRI] = useState('');
    const [Angle, setAngle] = useState('');
    const [GGraph, setGGraph] = useState('');
    const [Puissance, setPuissance] = useState('');
    const [finitionField, setFinitionField] = useState('');
    const [pdf, setPdf] = useState('');
    const [generate, setGenerate] = useState('');
    const [FluxP, setFluxP] = useState('');
    const [color, setColor] = useState('');
    const [driver, setdriver] = useState('');
    const [angle, setangle] = useState('');
    const [GraphG, setGraphG] = useState('');
    const [puissance, setpuissance] = useState('');
    const [codeProd, setcodeProd] = useState('');
    const [Description, setDescription] = useState('');
    const [typeCat, settypeCat] = useState('');
    const [nbrLed, setnbrLed] = useState('');
    const [fluxData, setfluxData] = useState([]);
    const [longeur, setlongeur] = useState('');
    const [typeSource, settypeSource] = useState('');
    const [formDiff, setformDiff] = useState('');
    const [natureDiff, setnatureDiff] = useState('');
    const [Longeur, setLongeur] = useState('');
    const [TypeSource, setTypeSource] = useState('');
    const [FormDiff, setFormDiff] = useState('');
    const [NatureDiff, setNatureDiff] = useState('');
    const [puissanceImg, setpuissanceImg] = useState('');
    const history = useHistory();
    const customStyles = { control: (base, state) => ({ ...base, background: " white", color: "black", height: 60, textAlign: "center", fontSize: 14, border: "1px solid black", cursor: "pointer" }) }; 

const ColorChange = (e) =>{ setCouleur(e.value); }
const DriverChange = (e) =>{ setDriver(e.value); }
const IPChange = (e) =>{ setIP(e.value); }
const CriChange = (e) =>{ setCRI(e.value); }
// const AngleChange = (e) =>{ setAngle(e.value); }
// const AngleGChange = (e) =>{ setGGraph(e.value); }
const PuissanceChange = (e) =>{ setPuissance(e.value); }
const FinitionChange = (e) =>{ setFinitionField(e.value); }
const TypeSourceChange = (e) =>{ setTypeSource(e.value); }
const FormDiffChange = (e) =>{ setFormDiff(e.value); }
const NatureDiffChange = (e) =>{ setNatureDiff(e.value); }
const LongeurChange = (e) =>{ setLongeur(e.value); }

const come = useCallback(() =>{
    if(props.PuissanceT !== "" && props.PuissanceT !== null){ setPuissance(props.PuissanceT); }
    if(props.DriverT !== "" && props.DriverT !== null){ setDriver(props.DriverT); }
    if(props.AngleT !== "" && props.AngleT !== null){ setAngle(props.AngleT); }
    if(props.GGraphT !== "" && props.GGraphT !== null){setGGraph(props.GGraphT);}
    if(props.CRIT !== "" && props.CRIT !== null){setCRI(props.CRIT);}
    if(props.Ip !== "" && props.Ip !== null){setIP(props.Ip);}
    if(props.ColorT !== "" && props.ColorT !== null){setCouleur(props.ColorT);}
    if(props.Longeur !== "" && props.Longeur !== null){setLongeur(props.Longeur);}
    if(props.TypeSource !== "" && props.TypeSource !== null){setTypeSource(props.TypeSource);}
    if(props.FormDiff !== "" && props.FormDiff !== null){setFormDiff(props.FormDiff);}
    if(props.NatureDiff !== "" && props.NatureDiff !== null){setNatureDiff(props.NatureDiff);}
},[props.AngleT, props.CRIT, props.ColorT, props.DriverT, props.GGraphT, props.NatureDiff, props.FormDiff, props.Longeur, props.TypeSource, props.PuissanceT, props.Ip])

const onColorClick = useCallback(() =>{
    for (const dataObj of props.puissance) {
        if(dataObj.puissanceName === Puissance){ setnbrLed(dataObj.nbrLed)
            axios.get("http://localhost:8080/getFluxByPuissance/"+dataObj.idpuissance)
            .then(response => response.data).then((data)=> { 
                setfluxData(data);   
        }) } } },[props.puissance, Puissance]) 

const findFlux = useCallback(() =>{
    var found = ""
    if(fluxData.length === 1){
        for (const dataObj of fluxData) {
            let sou = dataObj.fluxName;sou = sou.replace(/[^0-9.]/g, ''); 
            sou = sou +" LM"; found= sou;
        }  } 
    if(fluxData.length === 2){ var vlueLM = []
        for (const dataObj of fluxData) {
            let sou = dataObj.fluxName; sou = sou.replace(/[^0-9.]/g, ''); vlueLM = vlueLM.concat(sou); vlueLM = vlueLM.sort();
            if(Couleur === "2700K"){ let first = vlueLM[0] + " LM"; found= first }
            if(Couleur === "3000K"){ var colorL = []; 
                for (const dataObjC of props.color) { colorL = colorL.concat(dataObjC.colorName); }
                if(colorL.includes("2700K")){ let first = vlueLM[1] + " LM"; found= first }
                if(colorL.includes("4000K")){ let first = vlueLM[0] + " LM"; found= first } }
            if(Couleur === "4000K"){ let first = vlueLM[1] + " LM"; found= first} } }
    if(fluxData.length === 3){ var vlueLM3 = []
        for (const dataObj of fluxData) {
            let sou = dataObj.fluxName; sou = sou.replace(/[^0-9.]/g, '');
            vlueLM3 = vlueLM3.concat(sou); vlueLM3 = vlueLM3.sort();
            if(Couleur === "2700K"){ let first = vlueLM3[0] + " LM"; found= first }
            if(Couleur === "3000K"){ let first = vlueLM3[1] + " LM"; found= first }
            if(Couleur === "4000K"){ let first = vlueLM3[2] + " LM"; found= first}
} } 
    var Long = Longeur;
        Long = Long.replace(/[^0-9.]/g, '');
            found= found.replace(/[^0-9.]/g, '');
            found= found*(Long/1000); 
            found= Math.floor(found)+"LM";
        setFluxP(found); 
},[Couleur, fluxData, props.color, Longeur])

useEffect(() =>{ 
    come()
}, [come]);

useEffect(() =>{ 
    if(props.puissance.length !== 0 && Puissance !== ""){ 
        onColorClick();
    }
}, [onColorClick, props.puissance.length, Puissance]);


useEffect(() =>{ 
    if(fluxData.length !== 0 && Couleur !== ""){ 
        findFlux();
    }
}, [findFlux, fluxData, Couleur]);

const submitAll = (event) =>{
    event.preventDefault();
    setPdf("pdf");setGenerate("g"); settypeCat(props.data.typecategorie.nomType)
      var name= ""; var taillet =[]; var fini =[]; var fin = ""; var tcolor =[]; var c1 =""; 
      var c2= ""; var clr=""; var form =""; var taille =""; var puis = ""; var crt =[]; var cr =""; var drivert =[]; var dr = "";
      var productName = props.data.nomProduit; var productPuissance = ""; 
      var productFinition = ""; var productColor = ""; var productDriver = "";
      var productCri = ""; var t1=""; var t2=""; var t3="";
      var formD = ""; var formDE = ""
      if (FormDiff !== null && FormDiff !== "" && FormDiff !== "NULL" && FormDiff !== "undefined"){
        formD = FormDiff.split(""); formD= formD[0];
      }
      var nature = ""; 
      if (NatureDiff !== null && NatureDiff !== "" && NatureDiff !== "NULL" && NatureDiff !== "undefined"){
        nature = NatureDiff.split(""); nature= nature[0];
      }
      name= props.data.nomProduit;
      name = name.split(' ');
      if(props.data.nomProduit.includes("SCOOBA")){
        name = name[0]+" "+name[1]+"-";
      }else{
        name = name[0]+"-";
      }
      
      var section = "";
      if (props.data.section !== null && props.data.section !== "" && props.data.section !== "NULL" && props.data.section !== "undefined"){
        section = props.data.section;
      }
      if(Puissance != null){ productPuissance= Puissance; }
      var longeur = "";
      if (Longeur !== null && Longeur !== "" && Longeur !== "NULL" && Longeur !== "undefined"){
            var Long = Longeur;
                  Long = Long.replace(/[^0-9.]/g, ''); 
                  var Puissan = Puissance;
                  Puissan=Puissan.replace(/[^0-9.]/g, ''); 
                  Puissan = Puissan*(Long/1000);
                  if(!Number.isInteger(Puissan)){
                  Puissan= Puissan.toFixed(1); }
                  if(Puissance !== "undefined"){ setpuissance(Puissan+"W") }
          longeur = Long.split(""); longeur= longeur[0]+longeur[1];
          puis = Puissan;
          productPuissance= puis + "W";
      }else{
        if(Puissance !== "undefined"){ setpuissance(Puissance) }
            Puissan = Puissance;
            Puissan=Puissan.replace(/[^0-9.]/g, ''); 
            puis = Puissan;
                
      }
      var source = "";
      if (TypeSource !== null && TypeSource !== "" && TypeSource !== "NULL" && TypeSource !== "undefined"){
         source = TypeSource.split(""); source= source[0];
      }
    
      crt = CRI.replace(/[^0-9.xX,]/g, '').split("");
      if(crt[0] == null){ cr="" }else{ cr = crt[0] }
      if(finitionField === "Autre choix"){ fini = finitionInitial.split("");
        if(fini[0] == null){ fin="" }else{ fin = fini[0] } }
      if(finitionField !== "Autre choix"){ fini = finitionField.split("");
        if(fini[0] == null){ fin="" }else{ fin = fini[0] } }
    if(finitionField === "Autre choix"){ setfinitionFieldData(finitionInitial) }
    if(finitionField !== "Autre choix"){ setfinitionFieldData(finitionField) }
    if(Couleur === "RGBW"){ clr = "CB" }
    if(Couleur === "RGB"){ clr = "C" }
    if(Couleur === "2700K" || Couleur === "3000K" || Couleur === "4000K"){
      tcolor = Couleur.split("");
      if(tcolor[0] == null){ c1="" }else{ c1 = tcolor[0] } if(tcolor[1] == null){ c2="" }else{ c2 = tcolor[1] }
      clr = c1+c2
    }
    drivert= Driver;
    if(drivert === "DMX"){ dr="DX" }
    if(drivert === "Dimmable" || drivert === "1-10V" || drivert === "0-10V"){ dr="D" }
    if(drivert === "DALI" ){ dr="DA" } if(drivert==="ON/OFF"){ dr=""; }
    if(props.data.form !== null && props.data.form !== "" && props.data.form !== "NULL" && props.data.form !== "undefined")
    {  form = props.data.form.split(""); form = form[0]; }
    if(props.data.form !== null && props.data.form !== "" && props.data.form !== "NULL" && props.data.form !== "undefined")
    {  formDE = props.data.form; }
    if(props.data.taille == null){ taille =""; }else{
       taillet = props.data.taille.split("");
       if(taillet[0] == null){ t1="" }else{ t1 = taillet[0] } if(taillet[1] == null){ t2="" }else{ t2 = taillet[1] }
       if(taillet[2] == null){ t3="" }else{ t3 = taillet[2] } taille = t1+t2+t3; }
    if(Couleur !== "undefined"){ setColor(Couleur) }
    if(Driver !== "undefined"){ setdriver(Driver) } 
    if(Angle !== "undefined"){ setangle(Angle) }
    if(GGraph !== "undefined"){ setGraphG(GGraph) }
    if(TypeSource !== "undefined"){ settypeSource(TypeSource) }
    if(FormDiff !== "undefined"){ setformDiff(FormDiff) }
    if(NatureDiff !== "undefined"){ setnatureDiff(NatureDiff) }
    if(Puissance !== "undefined"){ setpuissanceImg(Puissance) }
    if(Longeur !== "undefined"){ setlongeur(Longeur) }
    if(Couleur != null){ productColor= Couleur; }
    if(Driver != null){ productDriver= Driver; }
    if(CRI != null){ productCri= "CRI" +CRI; }
    if(finitionField != null){ productFinition= finitionField; }
    var sectionR = ""; 
    if(props.data.sectionR !==null && props.data.sectionR !== "" && props.data.sectionR !=="NULL" && props.data.sectionR !== "undefined"){
        sectionR = props.data.sectionR; sectionR= sectionR.replace(/[^0-9.]/g, ''); 
    }
    var LEDSM = "";
    var Led = "";
    if (props.data.ledSM !== null && props.data.ledSM !== "" && props.data.ledSM !== "NULL" && props.data.ledSM !== "undefined"){
        LEDSM = props.data.ledSM;
        Led = props.data.ledSM;
        Led=Led.replace(/[^0-9.]/g, ''); 
    }
    var Largeur = "";
    var Larg = "";
    if (props.data.largeur !== null && props.data.largeur !== "" && props.data.largeur !== "NULL" && props.data.largeur !== "undefined"){
        Largeur = props.data.largeur;
        Larg = props.data.largeur;
        Larg=Larg.replace(/[^0-9.]/g, ''); 
    }
    var IPproduct = "";
    if(IP !== null && IP !== "" && IP !== "NULL" && IP !== "undefined"){
        IPproduct = "IP"+IP;
    }
    var IKproduct= "";
    if (props.data.ik !==null && props.data.ik !== "" && props.data.ik !== "NULL" && props.data.ik !== "undefined"){
        IKproduct= "IK"+props.data.ik;
    }
    if(productFinition==="Autre choix"){
        productFinition=""
    }

    if(props.type.includes("RUBAN") || props.type.includes("Ruban")){
        setcodeProd(name+form+formD+nature+taille+fin+puis+section+sectionR+longeur+Larg+Led+source+cr+clr+dr)
        setDescription(productName+ "  " +formDE+ " " +productFinition+ "  " +productPuissance+ "  " +Longeur+ "  " +Largeur+ "  "  +TypeSource+ "  " +LEDSM+ "  " +productCri+ "  " +productColor+ "  " +productDriver+ " "+IPproduct+ " "+IKproduct)       
    }else{
        if(props.data.nomProduit.includes("ALTUS") || props.data.nomProduit.includes("Altus") || props.data.nomProduit.includes("altus")){
            setcodeProd(name+form+section+formD+nature+taille+fin+Larg+puis+sectionR+Led+cr+clr+dr)
            setDescription(productName+ "  " +formDE+ " " +productFinition+ "  " +Largeur+ "  "  +productPuissance+ "  " +LEDSM+ "  " +productCri+ "  " +productColor+ "  " +productDriver+ " "+IPproduct+ " "+IKproduct)
        }else{
            setcodeProd(name+form+section+formD+nature+taille+fin+longeur+Larg+source+puis+sectionR+Led+cr+clr+dr)
            setDescription(productName+ "  " +formDE+ " " +productFinition+ "  " +Longeur+ "  " +Largeur+ "  "  +TypeSource+ "  " +productPuissance+ "  " +LEDSM+ "  " +productCri+ "  " +productColor+ "  " +productDriver+ " "+IPproduct+ " "+IKproduct)
        }
        if(props.data.nomProduit.includes("DV25") || props.data.nomProduit.includes("dv25")){
            setcodeProd(name+form+section+formD+nature+taille+fin+Larg+source+puis+sectionR+Led+cr+clr+dr)
            setDescription(productName+ "  " +formDE+ " " +productFinition+ "  " +Largeur+ "  " +TypeSource+ "  " +productPuissance+ "  " +LEDSM+ "  " +productCri+ "  " +productColor+ "  " +productDriver+ " "+IPproduct+ " "+IKproduct)
        }
    }
}



var datapuissance=[];
props.puissance.map((p)=>( datapuissance = datapuissance.concat([{ value: p.puissanceName, label: p.puissanceName }]) ))
var datacri=[];
props.cri.map((c)=>( datacri = datacri.concat([{ value: c.criName, label: c.criName }]) ))
var dataColor=[];
props.color.map((c)=>( dataColor = dataColor.concat([{ value: c.colorName, label: c.colorName }]) ))
var datadriver=[];
props.driver.map((c)=>( datadriver = datadriver.concat([{ value: c.driverName, label: c.driverName }]) ))
var dataangle=[];
props.angle.map((c)=>( dataangle = dataangle.concat([{ value: c.angleName, label: c.angleName }]) ))
var dataangleG=[];

var datatypesource=[];
props.typeSource.map((p)=>( datatypesource = datatypesource.concat([{ value: p.typeSource, label: p.typeSource }]) ))
var datanaturediff=[];
props.natureDiff.map((p)=>( datanaturediff = datanaturediff.concat([{ value: p.natureDiff, label: p.natureDiff }]) ))
var dataformdiff=[];
props.formDiff.map((p)=>( dataformdiff = dataformdiff.concat([{ value: p.formDiff, label: p.formDiff }]) ))
var datalongeur=[];
props.longeur.map((p)=>( datalongeur = datalongeur.concat([{ value: p.longeurName, label: p.longeurName }]) ))

props.angleGraph.map((c)=>( dataangleG = dataangleG.concat([{ value: c.angleGraphName, label: c.angleGraphName }]) ))
var dataFinition = [{value: props.data.finition , label: props.data.finition},{value: "Autre choix" , label: "Autre choix"}];
    dataangle = dataangle.filter((item) => item.value !== ""); dataangleG = dataangleG.filter((item) => item.value !== "");
    dataangleG = dataangleG.filter((item) => item.value !== "undefined"); dataangleG = dataangleG.filter((item) => item.value !== null);
    datapuissance = datapuissance.filter((item) => item.value !== ""); dataangle = dataangle.filter((item) => item.value !== null);
    datapuissance = datapuissance.filter((item) => item.value !== null); dataColor = dataColor.filter((item) => item.value !== null);
    datacri = datacri.filter((item) => item.value !== null);dataFinition = dataFinition.filter((item) => item.value !== null); 
    datadriver = datadriver.filter((item) => item.value !== null); dataFinition = dataFinition.filter((item) => item.value !== "undefined");
    dataFinition = dataFinition.filter((item) => item.value !== ""); datatypesource = datatypesource.filter((item) => item.value !== "undefined");
    datatypesource = datatypesource.filter((item) => item.value !== ""); datatypesource = datatypesource.filter((item) => item.value !== null);
    datanaturediff = datanaturediff.filter((item) => item.value !== "undefined"); datanaturediff = datanaturediff.filter((item) => item.value !== ""); 
    datanaturediff = datanaturediff.filter((item) => item.value !== null); dataformdiff = dataformdiff.filter((item) => item.value !== "undefined");
    dataformdiff = dataformdiff.filter((item) => item.value !== ""); dataformdiff = dataformdiff.filter((item) => item.value !== null);
    datalongeur = datalongeur.filter((item) => item.value !== "undefined"); datalongeur = datalongeur.filter((item) => item.value !== ""); 
    datalongeur = datalongeur.filter((item) => item.value !== null);
    
// var angleT=""; 
// var angleGT=""; 
// if(dataangle.length !== 0){ angleT="angle"; } if(dataangleG.length !== 0){ angleGT="angleG"; }
var ipdata=[];
props.ip.map((p)=>( ipdata = ipdata.concat([{ value: p.nombre, label: p.nombre }]) ))
ipdata = ipdata.filter((item) => item.value !== ""); ipdata = ipdata.filter((item) => item.value !== null);

const Select = props => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
    />
  );


  const Ies = () =>{

    if(localStorage.getItem("username") === null && localStorage.getItem("email") === null  ){
        history.push("/Register and Login");
    }
    
    if(localStorage.getItem("email") !== null && localStorage.getItem("username") !== null){
        window.open(props.data.ies);
    }

}


const intl = useIntl();
const placeholderc = intl.formatMessage({ id: "color" });
const placeholderp = intl.formatMessage({ id: "power" });
// const placeholdera = intl.formatMessage({ id: "angle" });
const placeholderl = intl.formatMessage({ id: "length" });
const placeholders = intl.formatMessage({ id: "source" });
const placeholdern = intl.formatMessage({ id: "nature" });
const placeholderf = intl.formatMessage({ id: "form" });

  return (
    <Container> 
    <div className="calculate-shipping">
    <h4 style={{marginTop:30,marginBottom:50,textTransform:'uppercase'}}><FormattedMessage id="configtitle" defaultMessage="Configurateur De Produit" /></h4></div>
        <div>
            <Form onSubmit={submitAll} id="fornId">
                <Row style={{paddingLeft:0,paddingRight:0}}>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-right" style={{pointerEvents: dataFinition.length === 0? "none": "all"}}>
                        <Select
                            placeholder="Finition"
                            value= {dataFinition.find(objpf => objpf.value === finitionField)}
                            options={dataFinition} 
                            onChange={FinitionChange} 
                            styles={customStyles}
                            required = {dataFinition.length === 0? "": true}
                        />
                    </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12" >
                        <FormGroup data-aos="fade-right" style={{pointerEvents: datapuissance.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholderp}
                                value= {datapuissance.find(objp => objp.value === Puissance)}
                                options={datapuissance} 
                                onChange={PuissanceChange} 
                                styles={customStyles}
                                required = {datapuissance.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-right" style={{pointerEvents: datacri.length <= 1? "none": "all"}}>
                            <Select
                                placeholder="CRI"
                                value= {datacri.find(objcri => objcri.value === CRI)}
                                options={datacri} 
                                onChange={CriChange} 
                                styles={customStyles}
                                required = {datacri.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-left" style={{pointerEvents: dataColor.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholderc}
                                value= {dataColor.find(objcolor => objcolor.value === Couleur)}
                                options={dataColor} 
                                onChange={ColorChange}
                                styles={customStyles}
                                required = {dataColor.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    {/* {angleT === "angle"? 
                        <Col lg="2" md="4" sm="12">
                            <FormGroup data-aos="fade-left" style={{pointerEvents: dataangle.length <= 1? "none": "all"}}>
                                <Select
                                    placeholder={placeholdera}
                                    value= {dataangle.find(objangle => objangle.value === Angle)}
                                    options={dataangle}
                                    onChange={AngleChange} 
                                    styles={customStyles}
                                    required = {dataangle.length <= 1? "": true}
                                />
                            </FormGroup>
                        </Col>
                    : "" } */}
                     {/* {angleGT === "angleG"? 
                        <Col lg="2" md="4" sm="12">
                            <FormGroup data-aos="fade-left" style={{pointerEvents: dataangleG.length <= 1? "none": "all"}} >
                                <Select
                                    placeholder={placeholdera}
                                    value= {dataangleG.find(objangleGG => objangleGG.value === GGraph)}
                                    options={dataangleG}
                                    onChange={AngleGChange} 
                                    styles={customStyles}
                                    required = {dataangleG.length <= 1? "": true}
                                />
                            </FormGroup>
                        </Col>
                        : "" } */}
                     {/* {dataangleG.length=== 0 && dataangle.length === 0? 
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-left" style={{pointerEvents: dataangleG.length <= 1 && dataangle.length <= 1? "none": "all"}} >
                            <Select
                                placeholder={placeholdera}
                                value= {dataangle.find(objangle => objangle.value === Angle)}
                                options={dataangle}
                                onChange={AngleChange} 
                                styles={customStyles}
                                required = {dataangleG.length <= 1 && dataangle.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    : "" } */}
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-left" style={{pointerEvents: datadriver.length <= 1? "none": "all"}} >
                            <Select
                                placeholder="Driver"
                                value= {datadriver.find(objdriver => objdriver.value === Driver)}
                                options={datadriver} 
                                onChange={DriverChange} 
                                styles={customStyles}
                                required = {datadriver.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-right" style={{pointerEvents: datatypesource.length === 0? "none": "all"}}>
                            <Select
                                placeholder={placeholders}
                                value= {datatypesource.find(objpf => objpf.value === TypeSource)}
                                options={datatypesource} 
                                onChange={TypeSourceChange} 
                                styles={customStyles}
                                required = {datatypesource.length === 0? "": true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{paddingLeft:0,paddingRight:0,marginTop:20}}>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-right" style={{pointerEvents: dataformdiff.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholderf}
                                value= {dataformdiff.find(objp => objp.value === FormDiff)}
                                options={dataformdiff} 
                                onChange={FormDiffChange} 
                                styles={customStyles}
                                required = {dataformdiff.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-right" style={{pointerEvents: datanaturediff.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholdern}
                                value= {datanaturediff.find(objcri => objcri.value === NatureDiff)}
                                options={datanaturediff} 
                                onChange={NatureDiffChange} 
                                styles={customStyles}
                                required = {datanaturediff.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup data-aos="fade-left" style={{pointerEvents: datalongeur.length <= 1? "none": "all"}}>
                            <Select
                                placeholder={placeholderl}
                                value= {datalongeur.find(objcolor => objcolor.value === Longeur)}
                                options={datalongeur} 
                                onChange={LongeurChange}
                                styles={customStyles}
                                required = {datalongeur.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="2" style={{display:finitionField === "Autre choix"? "" : "none"}}>
                        <FormGroup data-aos="fade-right" >
                        <Input type="text" name="finitionInitial" value={finitionInitial} onChange={e => setFinitionInitial(e.target.value)}  autoComplete="off" placeholder="Finition" style={{borderRadius:"none",borderTop:"none",borderRight:"none",borderLeft:"none",marginTop:10,height:50,pointerEvents:finitionField === "Autre choix"? "all" : "none"}}  />                
                        </FormGroup>
                    </Col>
                    <Col lg="2" md="4" sm="12">
                        <FormGroup style={{pointerEvents: ipdata.length <= 1? "none": "all"}} >
                            <Select
                                placeholder="IP"
                                value= {ipdata.find(objdriver => objdriver.value === IP)}
                                options={ipdata} 
                                onChange={IPChange} 
                                styles={customStyles}
                                required = {ipdata.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <div className="row" style={{marginTop:50}}>
                    <div className="col-md-4 col-12 mb-25">
                        <div className="cart-summary">
                            <div className="cart-summary-button">
                                <Button type="submit" className="btn"><FontAwesomeIcon icon={faTools} /> <FormattedMessage id="config" defaultMessage="Configuration" /></Button>
                            </div>
                        </div>
                        {generate === "g" ?
                            <ProfilesDescCodPDF puissanceImg={puissanceImg} ip={props.ip} typeSource={typeSource} longeur={longeur} Longeur={Longeur} natureDiff={natureDiff} formDiff={formDiff} certification={props.certification} idProduit={props.data.idProduit} ies={props.data.ies} cod={codeProd} desc={Description} pdfS={pdf} yes={props.data} angleG={props.angle} angleGraphG={props.angleGraph} puis={props.puissance} finitionField={finitionFieldData} color={color} driverc={driver} angle={angle} GraphG={GraphG} puissance={puissance} sous={typeCat} codeProd={codeProd} cri={CRI} flux={FluxP} nbr={nbrLed} />
                        :  "" }
                        <div className="cart-summary" style={{pointerEvents:props.data.ies !== null && props.data.ies !== "" && props.data.ies !== "NULL"? "": "none"}}>
                            <div className="cart-summary-button">
                                <button className="btn" onClick={Ies}><FontAwesomeIcon icon={faDownload} /> <FormattedMessage id="ies" defaultMessage="Fichier IES" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mb-25">
                        <div className="cart-summary">
                            <div className="cart-summary-wrap">
                                <h3>&#x2023;<b> <FormattedMessage id="code" defaultMessage="Code Produit" />:</b></h3><h3 style={{paddingLeft:40}}>{codeProd}</h3>
                                <h3>&#x2023;<b> <FormattedMessage id="desc" defaultMessage="Désignation du produit" />:</b></h3><h3 style={{paddingLeft:40}}>{Description}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <Row className="codeGenerate">
                    <Col md="12" style={{textAlign:"center",marginTop:30}}>
                        <div style={{width:"100%",textAlign:"center"}}>
                            <Button type="submit" className="btn" data-aos="rotate-ac" data-aos-duration="500" data-aos-easing="ease-in-quad" >Envoyer</Button>
                        </div>
                    </Col>
                </Row>
                {generate === "g" ?
                    <ProfilesDescCodPDF puissanceImg={puissanceImg} ip={props.ip} typeSource={typeSource} longeur={longeur} Longeur={Longeur} natureDiff={natureDiff} formDiff={formDiff} certification={props.certification} idProduit={props.data.idProduit} ies={props.data.ies} cod={codeProd} desc={Description} pdfS={pdf} yes={props.data} angleG={props.angle} angleGraphG={props.angleGraph} puis={props.puissance} finitionField={finitionFieldData} color={color} driverc={driver} angle={angle} GraphG={GraphG} puissance={puissance} sous={typeCat} codeProd={codeProd} cri={CRI} flux={FluxP} nbr={nbrLed} />
                :  "" }   */}
            </Form>
        </div>
    </Container>
  );

}
export default  React.memo(ProfilesGenerator)

