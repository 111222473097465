import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import logo from "../../assets/images/logo.png";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"

class Footer extends Component{
  render(){
    return (
        <div className="footer-section section bg-black">
            <div className="footer-top section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-60 pb-lg-40 pb-md-30 pb-sm-15 pb-xs-5">
                <div className="container">
                    <div className="row">
                        <div className="footer-widget col-md-5">
                            <div className="footer-logo">
                                <img src={logo} alt="" onClick={() => {window.location.href="/Accueil"}} style={{cursor:"pointer"}} />
                            </div>
                            <p style={{textAlign: "justify"}}><FormattedMessage id="footer" defaultMessage="À ProposNous maitrisons le processus industriel de nos produits d'éclairage. Notre bureau d'étude intégré suit toutes les phases de notre production : conception, études d'optiques, température de jonction des LEDs... Offrant fiabilité et durabilité dans toutes les conditions climatiques possibles." /></p>
                        </div>
                        <div className="footer-widget col-md-1"></div>
                        <div className="footer-widget col-md-3">
                            <br/>
                            <h4 className="title"><span className="text"><FormattedMessage id="footeryup" defaultMessage="Qui sommes-nous" /></span></h4>
                            <ul className="ft-menu">
                                <li onClick={() => {window.location.href="/AboutUs"}} style={{cursor:"pointer"}}><FormattedMessage id="about" defaultMessage="À Propos" /></li>
                                <li onClick={() => {window.location.href="/Actuality"}} style={{cursor:"pointer"}}><FormattedMessage id="actuality" defaultMessage="Actualités" /></li>
                                <li onClick={() => {window.location.href="/Documentation"}} style={{cursor:"pointer"}}><FormattedMessage id="Documentation" defaultMessage="Documentation" /></li>
                                <li onClick={() => {window.location.href="/Politique"}} style={{cursor:"pointer"}}><FormattedMessage id="Politique" defaultMessage="Politique de confidentialité" /></li>
                                
                            </ul>
                        </div>
                        <div className="footer-widget col-md-3">
                            <br/>
                            <h4 className="title"><span className="text"><FormattedMessage id="footerh4" defaultMessage="Contactez nous" /></span></h4>
                            <ul className="ft-menu">
                                <li>Rua da Quinta do Charquinho 25 rc esq</li>
                                <li>1 500/530 Lisboa Portugal</li>
                                <li>Nif 5071 01600</li>
                                <li>info@werdell.com</li>
                                <br/>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom section">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-12 ft-border pt-25 pb-25">
                            <div className="row justify-content-between">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="copyright text-left">
                                        <p>&copy;2024 - <Link to="/Accueil">WERDELL LIGHTING</Link></p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-nav text-right">
                                        <div className="footer-social">
                                            <FontAwesomeIcon icon={faFacebook} style={{marginRight:10,marginLeft:10}} />
                                            <FontAwesomeIcon icon={faTwitter} style={{marginRight:10,marginLeft:10}} />
                                            <FontAwesomeIcon icon={faLinkedin} style={{marginRight:10,marginLeft:10}} />
                                            <FontAwesomeIcon icon={faYoutube} style={{marginRight:10,marginLeft:10}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
}
export default Footer;
