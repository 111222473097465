import React, { useState } from 'react';
import emailjs from "emailjs-com";
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import bg from "../../assets/images/bg/breadcrumb.jpg";
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faHome } from '@fortawesome/free-solid-svg-icons';


const Contact = () => {

    // const date = new Date().getDate()  + "-" + (new Date().getMonth()+1) + "-" + new Date().getFullYear();
    const [nom,setNom]= useState('');
    const [email,setEmail]= useState('');
    const [subject,setSubject]= useState('');
    const [message,setMessage]= useState('');
 
    const intl = useIntl();
    const placeholdern = intl.formatMessage({ id: "nom" });
    const placeholdere = intl.formatMessage({ id: "email" });
    const placeholders = intl.formatMessage({ id: "subject" });
    const placeholderm = intl.formatMessage({ id: "message" });    


  const nomChange = (event) =>{
    setNom(event.target.value);
 }
 const emailChange = (event) =>{
    setEmail(event.target.value);
 }

 const subjectChange = (event) =>{
    setSubject(event.target.value);
 }

 const messageChange = (event) =>{
    setMessage(event.target.value);
 }


 const submitContact = (event) =>{

  event.preventDefault();

  emailjs.sendForm('gmail', 'template_wz7fx5b', event.target, 'user_moQYD2Pdi64W7a6dDoy9Q')
    .then((result) => {
        alert("votre message a été envoyé avec succès")
    }, (error) => {
        console.log(error.text);
    });

    setEmail("");
    setNom("");
    setSubject("");
    setMessage("")
  }
  

      return (

        <div id="main-wrapper">
        <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                            <h2>Contact</h2>
                            <ul className="page-breadcrumb">
                                <li><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
                                <li>Contact</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className="contact-map-section section">
          <iframe title="werdell lighting" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12445.29706779857!2d-9.198753!3d38.756266!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x999b678af773ccf4!2sWERDELL%20LIGHTING!5e0!3m2!1sfr!2sma!4v1620043513505!5m2!1sfr!2sma" width="100%" height="600" style={{border: 0}} allowFullScreen="" loading="lazy"></iframe>
        </div>

        <div className="conact-section section pt-95 pt-lg-75 pt-md-65 pt-sm-55 pt-xs-45">
        <div className="container sb-border pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div className="row">
                <div className="col-lg-3 col-12">
                    <div className="contact-information">
                        <h3><FormattedMessage id="footerh4" defaultMessage="Nous contacter" /></h3>
                        <ul>
                            <li>
                                <span className="icon"> <FontAwesomeIcon icon={faHome} style={{transform:"scale(1.4,1.4)"}} /></span>
                                <h4 className="text"><FormattedMessage id="contact1" defaultMessage="Addresse" /></h4>
                                <p>Rua da Quinta do Charquinho 25 rc esq,1 500/530 Lisboa Portugal Nif 5071 01600</p>
                            </li>
                            <li>
                                <span className="icon"><FontAwesomeIcon icon={faEnvelopeOpen} style={{transform:"scale(1.3,1.3)"}} /></span>
                                <h4 className="text">Email</h4>
                                <p>info@werdell.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-9 col-12">
                    <div className="contact-form-wrap">
                        <h3 className="contact-title"><FormattedMessage id="contact2" defaultMessage="Votre Message" /></h3>
                        <form onSubmit={submitContact} id="FormId" method="POST">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-form-style mb-20">
                                    <input
                                      type="text" 
                                      name="nom" 
                                      id="nom" 
                                      placeholder={placeholdern} 
                                      value={nom} 
                                      onChange={nomChange}  
                                      required
                                      />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="contact-form-style mb-20">
                                    <input
                                      type="email" 
                                      name="email" 
                                      id="email" 
                                      placeholder={placeholdere} 
                                      value={email} 
                                      onChange={emailChange}   
                                      required
                                      />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="contact-form-style mb-20">
                                        <input
                                          type="text" 
                                          name="subject" 
                                          id="subject" 
                                          placeholder={placeholders} 
                                          value={subject} 
                                          onChange={subjectChange}   
                                          required
                                          />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="contact-form-style">
                                        <textarea 
                                            type="textarea" 
                                            name="message" 
                                            id="message" 
                                            rows="7" 
                                            placeholder={placeholderm} 
                                            maxLength="195" 
                                            value={message} 
                                            onChange={messageChange} 
                                            required ></textarea>
                                        <button className="btn" type="submit"><span><FormattedMessage id="contact3" defaultMessage="Envoyer" /></span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p className="form-messege"></p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  <Footer />  
</div>

      );
    }
  

  export default Contact;

        