import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import SimpleSlider from "../HomePage/SimpleSlider";
import Pagination from "./Pagination";
import { FormattedMessage } from "react-intl";

const AllBlogs = ({Blogs, totalBlogs, blogsPerPage, paginate}) => {

    // console.log(Blogs)
    // if(loading){
    //     return <h2>Loading...</h2>
    // }

  return (

    <div className="blog-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50">
        <div className="container sb-border pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-list mb-40">
                            <div className="row">
                            {Blogs.map( (p) =>(
                                <div className="col-md-6" key={p.idBlog} style={{marginBottom: 30}}>
                                        <div className="blog-image">
                                            <img src={p.src} alt="" />
                                        </div>
                                    <br/>
                                        <div className="blog-content">
                                            <h3 className="title">{p.title}</h3>
                                            <ul className="meta">
                                                <li>{p.date}</li>
                                            </ul> 
                                            <Link to={{pathname: "/Blog_details/", search: `?idBlog=${p.idBlog}`}}  className="btn"><FormattedMessage id="more" defaultMessage="Lire la suite" /></Link>
                                        </div>
                                </div>
                                    )) 
                                }    
                        </div>

                    </div>
                </div>
            </div>

        <div className="pageNumber">
            <Pagination blogsPerPage={blogsPerPage} totalBlogs={totalBlogs} paginate={paginate} />
        </div>
        <SimpleSlider />
        
                </div>
            </div>
        </div>
    </div>
      );
    };
    
export default  React.memo(AllBlogs);