import React, {useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import axios from "axios";
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


const SearchComponent = (props) => {
    const [Produit, setProduit] = useState([]);
    const [Search, setSearch] = useState("");
    const [Match, setMatch] = useState("yes");
  
  const SeearchResult = useCallback(() =>{
    axios.get("http://localhost:8080/getAllProducts")
    .then(response => response.data)
    .then((data)=> {
      setProduit(data.filter(e =>
        Object.values(e)
          .join(" ")
          .toLowerCase()
          .match(Search.toLowerCase())
      )
      )
  });
 
  },[Search])
  
  useEffect(() =>{ 
    if(Search){
      SeearchResult();
     }
  }, [SeearchResult, Search]);
  

  const fromParent = () =>{
    props.Quite(false);
  }

  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: "search" }); 

  const match = () =>{
    setMatch("no");
  }

return(

<div>
<div className="wrap">
    <div className="search">
        
    <Input type="text" name="Search" id="Search" value={Search} onChange={e => setSearch(e.target.value)} placeholder={placeholder} required className="searchTerm" />

        {Produit.length !== 0?
        <Link to={{pathname: '/SearchResult', state: Produit}}>
            <Button className="searchButton" onClick={fromParent}>
              <FontAwesomeIcon icon={faSearch} style={{transform:"scale(1.3,1.3)"}} />
            </Button>
        </Link>
        :
            <Button className="searchButton" onClick={match}>
              <FontAwesomeIcon icon={faSearch} style={{transform:"scale(1.3,1.3)"}} />
            </Button>
        }
    </div>
    <div style={{width:"100%",display:"flex",justifyContent:"center",position:"fixed",bottom:"38%"}}>
      {Match === "no"?
      <p style={{color:"#ddd",fontSize:"1em"}}><FormattedMessage id="noresult" defaultMessage="Unfortunately, your search did not match any documents." /></p>
      :
      ""}
    </div>
    </div>
</div>
        )
    }

export default SearchComponent;
