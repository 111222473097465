import React from "react";
import { Container } from "reactstrap";
import "./navbar.css"

const GenericNotFound = () => (
    <Container>
        <div className="FourOhFour">
            <div className="code">ERROR 404</div>
        </div>
    </Container>
);

export default GenericNotFound;
