import React, {Component} from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import axios from "axios";
import { FormattedMessage } from "react-intl";


export default class Registration extends Component {
    constructor(props){
        super(props);
        this.state = this.initialState;
        this.userChange = this.userChange.bind(this);
        this.register = this.register.bind(this);
      }
      
    /*  the initial state of inputs */
    initialState = {
      username:"", confirmusername:"", email:"", entreprise:"", date: new Date().getDate()  + "-" + (new Date().getMonth()+1) + "-" + new Date().getFullYear()
    }  
     /* reset all inputs to the inistial state */
    resetUser = () =>{
      this.setState(() => this.initialState);
    }
    
    userChange = (event) =>{
      this.setState({
        [event.target.name]:event.target.value
      });
    }


    register = (e) =>{
      e.preventDefault();
      localStorage.clear("username");
      sessionStorage.clear("email");

      if(this.state.username !== this.state.confirmusername){
          alert("Wrong password");
      }else{
      const register = {
        username: this.state.username, 
        email: this.state.email,
        date: this.state.date,
        entreprise: this.state.entreprise
      }
      axios.get("http://localhost:8080/getEmailAPP/"+this.state.email)
      .then(RT=>{
        if(RT.data !== null && RT.data !== "" && RT.data !== "undefined"){
          alert("Cette adresse e-mail est déjà utilisée, essayez avec une autre adresse e-mail");
        }
        if(RT.data === null || RT.data === "" || RT.data === "undefined"){
        axios.post("http://localhost:8080/sentUserDetails", register)
        .then((result)=> { 
            alert("vous pouvez S'authentifier maintenant");
      })
      this.setState(() => this.initialState);
    }
  })
}
}

    render(){

        const{username, email, entreprise, confirmusername} = this.state;

        return (

            <div className="customer-login-register register-pt-0">
                <div className="form-register-title">
                    <h2><FormattedMessage id="register" defaultMessage="S'inscrire" /></h2>
                </div>
                <div className="register-form">
                    <form onSubmit={this.register} id="fornId" >
                        <div className="form-fild">
                            <p><label><FormattedMessage id="registeremail" defaultMessage="Nom d'utilisateur ou adresse e-mail " /><span className="required">*</span></label></p>
                            <input name="email" value={email} type="text" onChange={this.userChange} />
                        </div>
                        <div className="form-fild">
                            <p><label><FormattedMessage id="registerpasse" defaultMessage="Mot de passe " /><span className="required">*</span></label></p>
                            <input name="username" value={username} type="password" onChange={this.userChange} />
                        </div>
                        <div className="form-fild">
                            <p><label><FormattedMessage id="registerconfirm" defaultMessage="Confirmez le mot de passe" /> <span className="required">*</span></label></p>
                            <input name="confirmusername" value={confirmusername} type="password" onChange={this.userChange} />
                        </div>
                        <div className="form-fild">
                            <p><label><FormattedMessage id="registercompany" defaultMessage="Entreprise" /> <span className="required">*</span></label></p>
                            <input name="entreprise" value={entreprise} type="text" onChange={this.userChange} />
                        </div>
                        <div className="register-submit">
                            <button type="submit" className="btn"><FormattedMessage id="register" defaultMessage="S'inscrire" /></button>
                        </div>
                    </form>
                </div>
            </div>
                        
      );
    };
    
}