import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios"
import ConsultationDescriptionPart from './ConsultationDescriptionPart';
import GeneratorForm from './GeneratorForm';
import Footer from '../Footer/Footer';
import bg from "../../assets/images/bg/breadcrumb.jpg";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import { useHistory } from "react-router-dom";
// import PlusDeProduits from './PlusDeProduits';


const Consultation = (props) => {

    const [produits, setproduits] = useState([]);
    const [color, setcolor] = useState([]);
    const [douille, setdouille] = useState([]);
    const [Douille, setDouille] = useState([]);
    const [Color, setColor] = useState('');
    const [driver, setdriver] = useState([]);
    const [Driver, setDriver] = useState('');
    const [puissance, setpuissance] = useState([]);
    const [Puissance, setPuissance] = useState('');
    const [angle, setangle] = useState([]);
    const [Angle, setAngle] = useState('');
    const [angleGraph, setangleGraph] = useState([]);
    const [GGraph, setGGraph] = useState('');
    const [cri, setcri] = useState([]);
    const [CRI, setCRI] = useState('');
    const [accessoire, setaccessoire] = useState([]);
    const [coin, setcoin] = useState([]);
    const [ip, setip] = useState([]);
    const [Ip, setIp] = useState([]);
    const [longeur, setlongeur] = useState([]);
    // const [killed, setKilled] = useState([]);
    // const [typeName, settypeName] = useState('');
    const [certification, setcertification] = useState([]);
    const [typeR, settypeR] = useState('');

    const history = useHistory();
    

const findProdByIdSentInLink = useCallback((prodId) =>{
    
    axios.get("http://localhost:8080/getidP/"+prodId)
    .then(response => response.data)
    .then((data)=> {
        setproduits(data);
        // setprodName(data.nomProduit);
        // settypeName(data.typecategorie.categorie.nomCategorie);
    })
    axios.get("http://localhost:8080/getAngleByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setangle(data);
            if(data.length=== 1){
                for (const dataObj of data) {
                    setAngle(dataObj.angleName);
                }
            }
        })
    axios.get("http://localhost:8080/getAngleGraphByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setangleGraph(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setGGraph(dataObj.angleGraphName);
                }
            }
        })
    axios.get("http://localhost:8080/getCertificationByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setcertification(data);
        })
    axios.get("http://localhost:8080/getColorByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setcolor(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setColor(dataObj.colorName);
                }
            }
        })
    axios.get("http://localhost:8080/getCriByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setcri(data);
            if(data.length ===1){
                for (const dataObj of data) {
                   setCRI(dataObj.criName);
                }
            }
        })
    axios.get("http://localhost:8080/getDriverByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setdriver(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setDriver(dataObj.driverName);
                }
            }
        })
    axios.get("http://localhost:8080/getdatapuissanceProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setpuissance(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setPuissance(dataObj.puissanceName);
                }
            }
        })
    axios.get("http://localhost:8080/getDouilleByProduit/"+prodId)
    .then(response => response.data)
    .then((data)=> {
        setdouille(data);
        if(data.length === 1){
            for (const dataObj of data) {
                setDouille(dataObj.name);
            }
        }
    })
    axios.get("http://localhost:8080/getIpByProd/"+prodId)
    .then(response => response.data)
    .then((data)=> {
        setip(data);
        if(data.length === 1){
            for (const dataObj of data) {
                setIp(dataObj.nombre);
            }
        }
    })
    axios.get("http://localhost:8080/getaccessoireByProd/"+prodId)
    .then(response => response.data)
    .then((data)=> {
        setaccessoire(data);
    })
    axios.get("http://localhost:8080/getcoinByProd/"+prodId)
    .then(response => response.data)
    .then((data)=> {
        setcoin(data);
    })
    axios.get("http://localhost:8080/getlongeurByProd/"+prodId)
            .then(response => response.data)
            .then((data)=> {
                setlongeur(data);
            })
   
},[])


// const getAllProd = useCallback((type) =>{
//     axios.get("http://localhost:8080/getbyType/"+type)
//     .then(response => response.data)
//     .then((data)=> {
//         setKilled(data);
//     })
// },[])

useEffect(() =>{ 
    let prodId = new URLSearchParams(props.location.search).get('idP');
    findProdByIdSentInLink(prodId);
    settypeR(props.match.params.type);
    // if(props.match.params.type){
    //     let type = props.match.params.type;
    //     getAllProd(type);
    // }
}, [props.location.search,findProdByIdSentInLink,props.match.params]);



  return (
    <div id="main-wrapper">
    <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container">
            <div className="row">
                <div className="col">
                    
                    <div className="page-banner text-center">
                        <h2>{produits.nomProduit}</h2>
                        <ul className="page-breadcrumb">
                            <li className="gobackcategory" onClick={() => history.goBack()}>{typeR}</li>
                            <li>{produits.nomProduit}</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    <div style={{marginBottom:130}}>
        
        <ConsultationDescriptionPart typeName={typeR} coin={coin} longeur={longeur} accessoire={accessoire} details={produits} puissance={puissance} angleGraph={angleGraph} angle={angle} color={color}  driver={driver} cri={cri} />
        <GeneratorForm certification={certification} ip={ip} Ip={Ip} douille={douille} DouilleT={Douille} CRIT={CRI} ColorT={Color} AngleT={Angle} GGraphT={GGraph} DriverT={Driver} PuissanceT={Puissance} typeName={typeR} coin={coin} longeur={longeur} accessoire={accessoire} data={produits} puissance={puissance} angle={angle} angleGraph={angleGraph} color={color} driver={driver} cri={cri} />
        {/* <div style={{marginTop:60}}>
            <PlusDeProduits type={typeR} data={killed} />
        </div> */}
    </div>
    
        <Footer />
</div>
  );

}
export default  React.memo(Consultation)

