import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Wraper from "./components/Wraper";
import registerServiceWorker from "./registerServiceWorker";



ReactDOM.render(<Wraper><App /></Wraper>, document.getElementById("root"));
registerServiceWorker();
