import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import  logo  from "../../assets/images/logoPDF.png";

const styles = StyleSheet.create({
  page: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 25,
    fontFamily: "Montserrat",
    },
    title: {
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'Oswald'
      },
      image: {
        marginVertical: 15,
        marginHorizontal: 130,
        height:150,
      },
      border: {
        textDecoration: "none",
          padding: "1px",
          paddingTop:0,
          color: "#4a4a4a",
          backgroundColor: "grey",
          position: 'absolute',
          bottom: 60,
          left: 0,
          right: 0,
          flexGrow: 1
      },
      table: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: 0,
        paddingRight: 0,
      },
      tr: {
        width: '49%',
        marginRight: 0,
        paddingRight: 0,
        position: "relative",
        right: 0,
      },
      td:{
        borderBottom: "1 solid black",
        paddingBottom: 4,
        fontSize: 7,
        fontWeight: 3,
        marginBottom: 7,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 1
      },
      tr1: {
        width: '49%',
        marginRight: 0,
        paddingRight: 0,
        marginTop: 22
      },
      HeaderTop:{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position:"absolute",
        top: 20,
        right: 20,
        left: 20,
        border: "1 solid black",
        borderLeft: "none",
      },
      type: {
        width: '36%',
        textAlign: "center",
        paddingRight: 0,
        marginRight: 0,
        paddingLeft: 0,
        marginLeft: 0, 
      },
      logo: {
        width: '64%',
        paddingLeft: 0,
        marginLeft: 0,
        paddingTop: 4,
        paddingBottom: 4,
      },
      werdell:{
        height: 35,
        width: 150,
        paddingLeft: 0,
        marginLeft: -21,
        
      },
      typeText: {
        fontSize: 13,
        textTransform: "uppercase",
        color: "white",
        paddingTop: "5%",
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: "4%",
        height: "100%",
        backgroundColor: "black",
        textAlign: "center",
        fontFamily: "MontserratBold",
        lineHeight: 1.1,
      },
      nomP: {
        fontSize: 22,
        color: "#ee6b24",
        fontFamily: "MontserratBold"
      },
      t:{
        width: '60%',
        fontSize: 7,
      },
      t1: {
        width: '40%',
        textAlign: "left",
        fontSize: 6,
        paddingRight: 2,
        fontFamily: "MontserratThin",
        color: "black"
      },
      h : {
        color: "#ee6b24",
        fontSize: 10,
        paddingBottom: 10,
        textTransform: "uppercase",
      },
      h1 : {
        color: "#ee6b24",
        fontSize: 10,
        paddingBottom: 13,
        marginLeft: 18,
        textTransform: "uppercase",
      },
      tech: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // marginTop: 10,
        marginLeft: 15,
        borderBottom: "1 solid black",
      },
      tech1: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 15,
        borderBottom: "1 solid black",
        height: 42,
        paddingTop: 4
      },
      ipik : {
        width: "16px",
        height: "17px",
        marginRight: 1,
      },
      ipik1 : {
        width: "20px",
        marginRight: 1,
        fontSize: 5,
        textAlign: "center",
        paddingLeft:4,
        paddingRight:4,
        paddingTop: 3,
        borderBottomRightRadius: 5,
        border: "1 solid black",
      },
      ipikINL: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop:7,
        bottom: "1%"
      },
      te : {
        width: "16.30%",
        fontSize: 7,
        textAlign: "center",
        paddingBottom: 4,
      },
      code : {
        fontSize: 14,
        textTransform: "uppercase",
        marginTop: 0,
        color: "#000000",
        fontWeight: "normal",
        fontFamily: "Montserrat",
      },
      TitleHeader: {
        position: "absolute",
        top: "15%",
        marginLeft: 25,
        fontFamily: "MontserratBold"
      },
      disp: {
        display: "none",
      },
      te1 : {
        width: "16.30%",
        fontSize: 6.5,
        textAlign: "center",
        paddingBottom: 10,
        paddingTop:10,
        fontFamily: "MontserratThin",
        color: "black"
      },
      puisImg: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        bottom: 260,
        left: 25
      },
      puisImgCanal:{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        bottom: 220,
        left: 25
      },
      ipikCanal : {
        width: "105px",
        height: "80px",
        marginRight: 3,
      },
      puisImgACC: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        top: 165,
        right: 0,
        marginRight: -310
      },
      contPuis: {
        width: "45%"
      },
      contPuis1: {
        width: "58%",
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
      accessoires: {
        width: "29%",
      },
      botayaes: {
          marginBottom:10
      },
      footer: {
        position: "absolute",
        bottom: 0,
        textAlign: "center",
        borderTop: "1 solid lightgray",
        width: "100%",
        marginLeft: 20,
        marginRight: 20,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      footerL: {
        width: "50%",
        textAlign: "left",
        fontSize: 5.3,
      },
      footerR: {
        width: "50%",
        textAlign: "right",
        fontSize: 6,
      },
      footerText: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      tableG: {
        position: "absolute",
        bottom: 40,
        marginLeft: 25,
        marginRight: 0,
        paddingRight: 0,
        width: "100%",
      },
      ImageGlobal: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      ImageCont: {
        width: "50%",
        marginTop: 20
      },
      ImageCont2: {
        width: "15%",
      },
      ImageGlobalFix: {
        position: "absolute",
        top: "19%",
      },
      ImageCont1: {
        width: "35%",
      },
      anglePositionTop: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: "absolute",
        top: 200,
        right: 25
      },
      anglePos1: {
        width: "78%"
      },
      anglePos: {
        width: "22%",
      }, 
      anglePosmin: {
        width: "90%"
      },
      AnglePosMin1 : {
        width: "10%"
      },
      productImage:{
        width: 170,
        height: 170,
        marginTop:20
      },
      coinText:{
        Left: 23,
        fontFamily: "Montserrat",
        fontSize: 9,
        marginBottom: 87
      }
  });

    const PDFprodR = (props) => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View  style={styles.HeaderTop}>
              <View style={styles.logo}>
                  <Image src={logo} alt="" style={styles.werdell} />
              </View>
              <View style={styles.type}>
                  <Text style={styles.typeText}>{props.sous}</Text>
              </View>
          </View>

          <View style={styles.TitleHeader}>
              <Text style={styles.nomP}>{props.yes.nomProduit}</Text>
              <Text style={styles.code}>{props.codeProd}</Text>
          </View>

          <View style={styles.ImageGlobalFix}>
            <View style={styles.ImageGlobal}>
              <View style={styles.ImageCont1}>

              </View>
              <View style={styles.ImageCont}>
                <Image
                  // style={styles.image}
                  src={props.yes.profilePicPath}
                  alt=""
                  style={styles.productImage}
                />
              </View>
              <View style={styles.ImageCont2}>

              </View>
            </View>
          </View>

     
            <View style={styles.puisImg}>
              <View style={styles.contPuis}>
                <Image src={props.yes.dimension} alt="" />
              </View>
            </View>

           {props.accessoire.length !== 0 ?
              <View style={styles.puisImgACC}>
                <View style={styles.contPuis1}>
                  {props.accessoire.map((a)=>(
                    <View style={styles.accessoires} key={a.idaccessoire}>
                      <Image src={a.image} style={styles.botayaes} alt="" />
                    </View> 
                 ))}
                </View>
              </View>
            :
            <Text></Text>
          }

          {props.coinO.length !== 0?
          <View style={styles.puisImgCanal}>
            <Text style={styles.coinText} >Coin de canal</Text>
          </View>
          :
          <Text></Text>
          }
          <View style={styles.puisImgCanal}>
              {props.coinO.map((a)=>(
                <View key={a.idcoin}>
                    <Image src={a.image} alt="" style={styles.ipikCanal} />
                </View>
              ))}
          </View>

          <View style={styles.tableG} >
            <View style={styles.table}>
              <View style={styles.tr}>
                <View style={styles.td}>
                  <Text style={styles.h}>PARAMÈTRES GÉNÉRALES</Text>
                </View>
                <View style={styles.td}> 
                  <Text style={styles.t}> Finition</Text>
                  <Text style={styles.t1}>{props.finitionField}</Text>
                </View>
                <View style={styles.td}> 
                  <Text style={styles.t}> Tension / Fréquence</Text>
                  <Text style={styles.t1}>{props.yes.tension}</Text>
                </View>
              </View>

            <View style={styles.tr1}> 
            <View style={styles.td}> 
                  <Text></Text>
                </View>
                <View style={styles.td}> 
                  <Text style={styles.t}> Dimension</Text>
                  <Text style={styles.t1}>{props.longeur}</Text>
                </View>
                <View style={styles.td}> 
                  <Text style={styles.t}> Classe de protection</Text>
                  <Text style={styles.t1}>{props.yes.classProtection}</Text>
                </View>
                <View style={styles.td}> 
                  <Text style={styles.t}> Driver</Text>
                  <Text style={styles.t1}>{props.driverc}</Text>
                </View>
                <View style={styles.ipikINL}>
                  {props.certification.map((a)=>(
                    <View key={a.idcertification}>
                        <Image src={a.image} alt="" style={styles.ipik} />
                    </View>
                  ))}
                  {props.ip.map((a)=>(
                    <View key={a.idIp}> 
                      <Text style={styles.ipik1}> IP {a.nombre}</Text>
                    </View>
                   ))}
                    {props.yes.ik !== "" && props.yes.ik !== "undefined" && props.yes.ik !== null? 
                   <Text style={styles.ipik1}> IK {props.yes.ik} </Text>
                   : <Text style={styles.ipik}> </Text>} 
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}></Text>
                   <Text style={styles.ipik}></Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                   <Text style={styles.ipik}> </Text>
                </View>
              </View>
            </View>
          </View> 

      <View style={styles.footer}>
        <View style={styles.footerL}>
          <Text style={styles.footerText}>WERDELL LIGHTING </Text>
        </View>
        <View style={styles.footerR}>
          <Text style={styles.footerText}>info@werdell.com | www.werdell.com</Text>
        </View>
      </View>
      </Page>
      </Document>
    );
    
    Font.register({
      family: 'Montserrat',
      src: '//db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.ttf'
    });
    Font.register({
      family: 'MontserratThin',
      src: '//db.onlinewebfonts.com/t/029177df870cce2b384f0610a6e1f82a.ttf'
    });
    Font.register({
      family: 'MontserratBold',
      src: '//db.onlinewebfonts.com/t/07eae3dd22b2a99864228b51f4f918a5.ttf'
    });
    

    export default React.memo(PDFprodR);

    