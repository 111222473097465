import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import eng from "../../assets/images/icons/en-gb.png";
import fr from "../../assets/images/icons/fr-fr.png";
import SearchComponent from "./SearchComponent";



const TopbarMobile = (props) => {
    const closeMobileMenu = () => {props.closeContent(false)}
    const Quite = (send) => props.closeContent(send);
    
    return (
      <div className="headerM">
        <ul>
            <li className="active" onClick={closeMobileMenu}><Link to="/Accueil">Accueil</Link></li>
            <li onClick={closeMobileMenu}><Link to="/Tous les categories">Produits</Link></li>
            <li onClick={closeMobileMenu}><Link to="/AboutUs">à propos</Link></li>
            <li onClick={closeMobileMenu}><Link to="/Actuality">Actualités</Link></li>
            <li onClick={closeMobileMenu}><Link to="/Contact">Contact</Link></li>
            <li onClick={closeMobileMenu}><Link to="/Documentation">Documentation</Link></li>
          </ul>
        <div className="languages">
            <ul> 
                <li onClick={closeMobileMenu}><Link to="/Accueil"><img src={eng} alt="" /></Link></li>
                <li onClick={closeMobileMenu} id="google_translate_element"><Link to="/Accueil"><img src={fr} alt="" /></Link></li>
            </ul> 
        </div>
        <div className="searchContentM">
          <SearchComponent Quite={Quite} />
        </div>
      </div>
    );
  };

export default TopbarMobile;

