import React, {Component} from "react";
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/iconfont.min.css"
import "../../assets/css/helper.css"
import "../../assets/css/style.css"
import { Link } from "react-router-dom";
import axios from "axios";
import { FormattedMessage } from "react-intl";


export default class Login extends Component {
    constructor(props){
        super(props);
        this.state = this.initialState;
        this.userChange = this.userChange.bind(this);
      }
      
    /*  the initial state of inputs */
    initialState = {
      username:"", email:"", isChecked: false
    }  
     /* reset all inputs to the inistial state */
    resetUser = () =>{
      this.setState(() => this.initialState);
    }
    
    userChange = (event) =>{
      this.setState({
        [event.target.name]:event.target.value
      });
    }

    toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }

    login = (e) =>{
      
      e.preventDefault();
      
      var store = this.state.username
      localStorage.clear("username");
      sessionStorage.clear("email");

      axios.get("http://localhost:8080/getEmailAPP/"+this.state.email)
      .then(R=>{ 
        if(R.data !== null && R.data !== "" && R.data !== "undefined"){
           if(R.data.username === store){
                localStorage.setItem("email",  R.data.email);
                localStorage.setItem("username",  R.data.username);
                localStorage.setItem("entreprise",  R.data.entreprise);
              window.location.reload();
              alert("connected");
          }
          if(R.data.username !== store){
            alert("essayez avec un autre nom d'utilisateur")
          }
        }
        if(R.data === null || R.data === "" || R.data === "undefined"){
          localStorage.clear("username");
          sessionStorage.clear("username");
          localStorage.clear("email");
          sessionStorage.clear("email");
          alert("essayez avec une autre adresse e-mail ou un autre nom d'utilisateur")
        }
      })
          
      this.setState(() => this.initialState);
      
    }


    render(){

        const{username, email} = this.state;
       
        return (
            
            <div className="customer-login-register">
                <div className="form-login-title">
                    <h2><FormattedMessage id="login" defaultMessage="S'identifier" /></h2>
                </div>
                <div className="login-form">
                    <form onSubmit={this.login} id="formId">
                        <div className="form-fild">
                            <p><label><FormattedMessage id="registeremail" defaultMessage="Nom d'utilisateur ou adresse e-mail " /> <span className="required">*</span></label></p>
                            <input name="email" value={email} type="text" onChange={this.userChange} />
                        </div>
                        <div className="form-fild">
                            <p><label><FormattedMessage id="registerpasse" defaultMessage="Mot de passe " /> <span className="required">*</span></label></p>
                            <input name="username" value={username} type="password" onChange={this.userChange} />
                        </div>
                        <div className="login-submit">
                            <button type="submit" className="btn"><FormattedMessage id="connexion" defaultMessage="Connexion" /></button>
                            <label>
                                <input className="checkbox" type="checkbox" defaultChecked={this.state.isChecked} onChange={this.toggleChange} />
                                <span><FormattedMessage id="remember" defaultMessage="Se souvenir de moi " /></span>
                            </label>
                        </div>
                        <div className="lost-password">
                            <Link to="/"><FormattedMessage id="forget" defaultMessage="Mot de passe oublié? " /></Link>
                        </div>
                    </form>
                </div>
            </div>
                            
      );
    };
    
}