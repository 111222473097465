import React, {Component} from "react";
import Footer from "../Footer/Footer.js";
import AboutCimelect from "./AboutCimelect.js";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";

export default class About extends Component {

    render() {
        return (
            <div>
                <AboutCimelect />
                <Footer />
            </div>
      );
    };
    
}