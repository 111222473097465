import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import bg from "../../assets/images/bg/b-profil.jpg";



const ProduitParSousCatProfileRubanLED = (props) => {

    const [produits, setproduits] = useState([]);
    const [type, setType] = useState('')


const findAllProd = useCallback((Search) =>{
    axios.get("http://localhost:8080/getbyType/PROFILES/"+Search)
    .then(response => response.data)
    .then((data)=> {
        setproduits(data);
        localStorage.setItem("idP",data.idProduit)
    });
},[])

useEffect(() =>{ 
  let Search = new URLSearchParams(props.location.search).get('type');
  setType(new URLSearchParams(props.location.search).get('type'));
  findAllProd(Search);
}, [findAllProd, props.location.search]);



return (
    <div id="main-wrapper">
    <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container">
            <div className="row">
                <div className="col">
                    
                    <div className="page-banner text-center">
                        <h2>{type}</h2>
                        <ul className="page-breadcrumb">
                            <li><Link to="/profiles">PROFILES / RUBANS LED</Link></li>
                            <li>{type}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div className="shop-section section">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="shop-area sb-border pb-70 pb-lg-50 pb-md-40 pb-sm-20 pb-xs-20">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="shop-product">
                                        <div id="myTabContent-2" className="tab-content">
                                            <div id="grid" className="tab-pane fade active show">
                                                <div className="product-grid-view">
                                                    <div className="row">
                                                    {produits.length === 0?
                                                      ""
                                                          :
                                                      produits.map((produit) => (
                                                          <div className="col-lg-3 col-md-6 col-sm-6" key={produit.idProduit}>
                                                            <div className="single-grid-product mb-30">
                                                                <div className="product-image">
                                                            <Link to={{pathname: '/consultation de produits/'+type, search: `?idP=${produit.idProduit}` }}>
                                                                  <img src={produit.profilePicPath} className="img-fluid" alt=""/>
                                                            </Link> 
                                                                  <span className="category"></span>
                                                                    <h3 className="title">{produit.nomProduit}</h3>
                                                          </div>
                                                        </div>
                                                        </div>
                                                      ))
                                                  }
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>

  <Footer />
      
</div>
  );

}
export default  React.memo(ProduitParSousCatProfileRubanLED)

