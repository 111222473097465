import React, {Component} from "react";
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/iconfont.min.css"
import "../../assets/css/helper.css"
import "../../assets/css/style.css"
import Login from "./Login";
import Registration from "./Registration";
import { Link } from "react-router-dom";
import bg from "../../assets/images/bg/breadcrumb.jpg";
import Footer from "../Footer/Footer";
import { FormattedMessage } from "react-intl";


export default class RegistrationLoginPage extends Component {

    render() {
        return (
            <div id="main-wrapper">
                <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h2>Login Register</h2>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
                                        <li>Login Register</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="login-register-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50">
                    <div className="container sb-border pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <Login />
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <Registration />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
      );
    };
    
}