import React, {useState, useEffect, useCallback} from 'react';
// import axios from "axios";
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer.js';
import bg from "../../assets/images/bg/breadcrumb.jpg";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import SimpleSlider from '../HomePage/SimpleSlider.js';
import Blogs from './Blogs.js';


const BlogDetails = (props) => {

    // const [blog] = useState([]);
    const [Img, setImg] = useState([]);
    const [Title, setTitle] = useState([]);
    const [Para1, setPara1] = useState([]);
    const [Para2, setPara2] = useState([]);

const findAllProd = useCallback((Search) =>{
//   axios.get("http://localhost:8080/getidBlogs/"+Search)
//     .then(response => response.data)
//     .then((data)=> {
//         setBlog(data);
//     });
//     axios.get("http://localhost:8080/getBlogDescriptionByBlog/"+Search)
//     .then(response => {
//         setPara1(response.data[0].text);
//         setPara2(response.data[1].text);
//     })

    for (var i in Blogs ){
        if(Blogs[i].idBlog===Search){
            setPara1(Blogs[i].para1);
            setPara2(Blogs[i].para2);
            setTitle(Blogs[i].title);
            setImg(Blogs[i].src)
        }
    }

    console.log(Search)

},[])

useEffect(() =>{ 
  let Search =  new URLSearchParams(props.location.search).get('idBlog');
  console.log(new URLSearchParams(props.location.search).get('idBlog'))
  findAllProd(Search);
}, [findAllProd, props.location.search]);


return (
  <div id="main-wrapper">
  <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
      <div className="container">
          <div className="row">
              <div className="col">
                  <div className="page-banner text-center">
                      <h2>Actualités</h2>
                      <ul className="page-breadcrumb">
                          <li><Link to="/Accueil">Accueil</Link></li>
                          <li>Actualités</li>
                      </ul>
                  </div>
                  
              </div>
          </div>
      </div>
  </div>

  <div className="blog-section section pt-100 pt-lg-80 pt-md-70 pt-sm-50 pt-xs-40">
      <div className="container sb-border pb-80 pb-lg-60 pb-md-50 pb-sm-60 pb-xs-50">
          <div className="row">
                  <div className="blog_area">
                      <article className="blog_single blog-details">
                          <div className="post-thumbnail img-full">
                              <img src={Img} alt="" />
                          </div>
                          <div className="postinfo-wrapper">
                              <div className="post-info">
                                  <h2>{Title}</h2>
                                  <div className="entry-summary blog-post-description">
                                      <blockquote><p>{Para1}</p></blockquote>
                                      <p style={{textAlign: "justify", display: "inline-block", whiteSpace: "pre-line"}}>{Para2}</p>
                                  </div>
                              </div>
                          </div>
                      </article>    
                    
                     <SimpleSlider />
              </div>
          </div>
      </div>
  </div>

  <Footer />
</div>
      );
    };
    
export default React.memo(BlogDetails)
 