
import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';

export default class ConsultationDescription extends Component{

  render(){
    
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

  return(
  <div id="main-wrapper">
    <div className="single-product-section section pb-100 pb-lg-80 pb-md-70 pb-sm-30 pb-xs-20">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="shop-area">
                          <div className="row">
                              <div className="col-md-6">
                                <div className="product-details-left">
                                    <div className="product-details-images slider-lg-image-1 tf-element-carousel">
                                        <div className="lg-image">
                                            <img src={this.props.details.profilePicPath} alt="" />
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="product-details-content">
                                    <h2>{this.props.details.nomProduit}</h2>
                                <div>
                                  <Slider {...settings} className="consulter">
                                    {this.props.puissance.map((p)=>(
                                      <div key={p.idpuissance}>
                                        <img src={p.image} alt="" />
                                      </div>
                                    ))}
                                  </Slider>
                                </div>
                                    <br/>
                                    <div className="product-description">
                                      <div>
                                        &#x2731; Finition: {this.props.details.finition} <br/>
                                        &#x2731; <FormattedMessage id="tension" defaultMessage="Tension / Fréquence" />: {this.props.details.tension} <br/>
                                        &#x2731; <FormattedMessage id="ugr" defaultMessage="Taux d’éblouissement unifié" /> (UGR): {this.props.details.ugr} <br/>
                                        &#x2731; <FormattedMessage id="sdcm" defaultMessage="Déviation standard de couleur" /> (SDCM): {this.props.details.sdcm} <br/>
                                        &#x2731; <FormattedMessage id="tempf" defaultMessage="Températures de fonctionnement" />: {this.props.details.tempFonctionnement} <br/>
                                        &#x2731; <FormattedMessage id="class" defaultMessage="Classe de protection" />: {this.props.details.classProtection}<br/>
                                        &#x2731; <FormattedMessage id="life" defaultMessage="Durée de vie" />: {this.props.details.dureeVie} <br/>
                                        &#x2731; Driver: <span>{this.props.driver.map((d) => (
                                            <div key={d.iddriver} style={{display:"inline"}}>
                                                <p style={{display:"inline"}}>{d.driverName + " /"} </p>
                                            </div>
                                          ))}
                                          </span> <br/>
                                        &#x2731; <FormattedMessage id="color" defaultMessage="Température de couleur" />: <span>
                                            {this.props.color.map((d) => (
                                              <div key={d.idcolor} style={{display:"inline"}}>
                                                  <p style={{display:"inline"}}>{d.colorName + " /"} </p>
                                              </div>
                                            ))}
                                          </span>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );

}
}




