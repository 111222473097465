import React, {Component} from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";


import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import bg from "../../assets/images/bg/breadcrumb.jpg";
import { FormattedMessage } from "react-intl";


export default class Politique extends Component {

    render() {
        return (
            <div id="main-wrapper">
                <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                
                                <div className="page-banner text-center">
                                    <h2><FormattedMessage id="politiqueh4" defaultMessage="Politique De Confidentialité" /></h2>
                                    <ul className="page-breadcrumb">
                                    <li><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
                                        <li><FormattedMessage id="politiqueh4" defaultMessage="Politique De Confidentialité" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="faq-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 ">
                    <div class="container sb-border pb-90 pb-lg-70 pb-md-60 pb-sm-50 pb-xs-40">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="faq-content">
                                    <div class="faq-desc">
                                        <h3><FormattedMessage id="politiqueh3" defaultMessage="Généralités" /></h3>
                                        <p><FormattedMessage id="politiqueh3p" defaultMessage="WERDELL LIGHTING accorde la plus grande importance à un traitement responsable des données personnelles. Nous voulons vous faire savoir quand nous recueillons des données vous concernant et lesquelles, et comment nous les utilisons. Nous avons pris des dispositions techniques et organisationnelles pour vous garantir que les directives relatives à la protection des données sont bien respectées aussi bien par nous-mêmes que par nos prestataires extérieurs." /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh31" defaultMessage="Responsabilité" /></h3>
                                        <p><FormattedMessage id="politiqueh31p" values={{ linebreak: <br /> }} defaultMessage="La société qui exploite ce site web est la WERDELL LIGHTING. Responsable au sens du Règlement général sur la protection des données (RGPD) est la société WERDELL LIGHTING SARL. Pour toutes les questions relatives à la confidentialité ou au traitement de vos données personnelles, vous pouvez nous contacter à l’adresse suivante :<br/>
                                        WERDELL LIGHTING SARL<br/>
                                        Rua da Quinta do Charquinho 25 rc esq<br/>
                                        1 500/530 Lisboa<br/>
                                        Portugal - Nif 5071 01600<br/>
                                        info@werdell.com" /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh32" defaultMessage="Données traitées" /></h3>
                                        <p><FormattedMessage id="politiqueh32p" defaultMessage="Selon que vous consultez notre site web et que vous acceptez des cookies, et en fonction des cookies acceptés, ou que vous vous abonnez à votre newsletter ou encore que vous commencez une relation commerciale avec nous, nous traitons différentes catégories de vos données personnelles." /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh33" defaultMessage="Durée de conservation" /></h3>
                                        <p><FormattedMessage id="politiqueh33p" defaultMessage="Dès lors que vos données personnelles sont traitées dans notre comptabilité, comme en cas de conclusion d’un contrat, elles seront conservées jusqu’à la fin des délais prescrits par les obligations légales de conservation. Conformément aux directives fiscales en vigueur, ce délai est de 7 ans. À des fins de garantie du produit, nous enregistrons des données choisies (nom, adresse, marchandise et date) pendant 10 ans." /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh34" defaultMessage="Objectifs du traitement" /></h3>
                                        <p><FormattedMessage id="politiqueh34p" defaultMessage="Nous traitons les données recueillies auprès de vous essentiellement à des fins d’exécution des contrats ou de 
                                            mesures précontractuelles. Nous sommes par ailleurs dans l’obligation de traiter certaines données, par 
                                            exemple en raison des dispositions légales en vigueur en matière de fiscalité et de droit des entreprises 
                                            ainsi que des dispositions contre la corruption et le blanchiment de fonds. Les données que vous mettez 
                                            librement à notre disposition, comme par exemple votre nom et votre adresse e-mail, sont traitées sur le 
                                            fondement de votre accord en vue du suivi du client, pour nos propres objectifs publicitaires comme 
                                            notamment l’envoi d’offres, de prospectus publicitaires et de newsletter (au format papier ou électronique) 
                                            ainsi qu’à des fins de renseignements sur la relation commerciale actuelle ou passée avec le client 
                                            (références). Le traitement des données s’effectue sur le fondement des dispositions légales du § 96 
                                            alinéa 3 de la loi autrichienne sur les télécommunications ainsi que de votre accord ou de la finalité 
                                            de l’exécution du contrat." /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh35" defaultMessage="Cookies" /></h3>
                                        <p><FormattedMessage id="politiqueh35p" defaultMessage="Notre site web utilise des cookies pour recueillir des données personnelles ainsi que des données de protocole
                                            internet et des indications sur les modèles de comportement des utilisateurs finaux lors de leur visite de 
                                            notre site. Si vous allez sur notre site, vous pouvez d’abord paramétrer des cookies. Vous pouvez alors 
                                            décider des catégories de cookies que vous autorisez. En fonction des cookies que vous acceptez, nous 
                                            recueillons des données différentes à votre sujet. Ceci permet de vous proposer une meilleure expérience 
                                            d’utilisation, de reconnaître vos préférences, de diagnostiquer des problèmes techniques et d’analyser des 
                                            évolutions ainsi que de vous faire des recommandations de produits adaptés à vos intérêts." /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh36" defaultMessage="Vos droits" /></h3>
                                        <p><FormattedMessage id="politiqueh36p" values={{
                                            i: (...chunks) => <i>{chunks}</i>,
                                            linebreak: <br />
                                            }} defaultMessage="En ce qui concerne l’utilisation de vos données personnelles, vous disposez des droits suivants:<br/>
                                            <i>Droit d’information (Art. 15 RGPD)</i>{linebreak}
                                            <i>Droit de rectification (Art. 16 RGPD)</i>{linebreak}
                                            <i>Droit de suppression (Art. 17 RGPD)</i>{linebreak}
                                            <i>Droit de limitation de l’utilisation des données (Art. 18 RGPD)</i>{linebreak}
                                            <i>Droit au transfert des données (Art. 20 RGPD)</i>{linebreak}
                                            <i>Droit de recours (Art. 21 RGPD)</i>{linebreak}
                                            <i>Droit de plainte auprès d’une autorité de surveillance (Art. 77 RGPD)</i>{linebreak}
                                            {linebreak}	
                                            Pour toutes questions ou imprécisions sur vos droits, nous restons
                                            volontiers à votre disposition à l’adresse e-mail suivante :
                                            info@werdell.com" /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh37" defaultMessage="Protection de vos données" /></h3>
                                        <p><FormattedMessage id="politiqueh37p" defaultMessage="Afin de protéger au mieux vos données de la perte, de la destruction, de l’accès ou de la modification 
                                            ainsi que de la divulgation, nous prenons des mesures techniques et organisationnelles. Nous évaluons 
                                            régulièrement ces mesures et les adaptons à l‘évolution technologique. Nous tenons à attirer votre 
                                            attention sur le fait qu’en dépit de nombreuses mesures, il ne nous est pas possible de garantir la 
                                            protection complète de vos données." /></p><br/>
                                        
                                        <h3><FormattedMessage id="politiqueh38" defaultMessage="Modifications des dispositions de protection des données" /></h3>
                                        <p><FormattedMessage id="politiqueh38p" defaultMessage="Des modifications de la loi ou de processus internes à l’entreprise peuvent rendre nécessaire 
                                            une adaptation à ces dispositions de protection à laquelle nous nous réservons en conséquence 
                                            le droit de procéder. Nous vous prions de relire régulièrement la présente déclaration de protection 
                                            des données." /></p><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div> 

      );
    };
    
}