import React from "react";
import Footer from "../Footer/Footer";
import SimpleSlider from "./SimpleSlider";
import Home from "./Home";



function HomePage() {
    
      return (

        <div>
            <Home />
            <div style={{marginBottom:130}} >
                <SimpleSlider />
            </div>
            <Footer />
        </div>

      );
    };
    
    export default HomePage;