import React, {Component} from 'react';
import GetInfoFromDb from './GetInfoFromDb';




export default class ConsultationDescriptionPart extends Component{

  render(){
    
  return(
       
    <GetInfoFromDb coin={this.props.coin} longeur={this.props.longeur} accessoire={this.props.accessoire} typeName={this.props.typeName} details={this.props.details} driver={this.props.driver} color={this.props.color} angle={this.props.angle} angleGraph={this.props.angleGraph} flux={this.props.flux} puissance={this.props.puissance} cri={this.props.cri} />     
      
  );

}
}




