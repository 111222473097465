import React, {useState} from 'react';
// import { Col, Row} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from "axios";
import PDFprodR from '../TechniqueFich/RailsFich.js';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const RailsCodePDF = (props) => {

        const [date] = useState(new Date().getDate()  + "-" + (new Date().getMonth()+1) + "-" + new Date().getFullYear());
        const [month] = useState(new Date().getMonth()+1);
        const history = useHistory();
    
        const FT = () =>{
            if(localStorage.getItem("username") === null && localStorage.getItem("email") === null  ){
                history.push("/Register and Login");
            }
            
        }
    
    const SendData = () =>{
        
    if(localStorage.getItem("email") !== null || localStorage.getItem("username") !== null){
        const contact = {
            nomUserFT: localStorage.getItem("username"),
            emailFT: localStorage.getItem("email"),
            telFT: localStorage.getItem("tel"),
            entreprise: localStorage.getItem("entreprise"),
            date: date,
            month: month
          }
          const prodUser = {
              dateDownload: date,
          }
          let result = axios.post("http://localhost:8080/savedataFT", contact)
          .then(result =>{
              return axios.post("http://localhost:8080/saveProdUserFT/"+result.data.idFT+"/"+props.idProduit, prodUser).then(res => {
          }
          )});
           return result;
        
        }
    }

    

    return(
        <div>
                    {/* <Row className="codeGenerateDesc">
                        <Col md="4"> 
                         <p className="paraCode">  <i className="indiceCode"> Le code du produit : </i> {props.codeProd}</p>
                        </Col>
                        <Col md="6">
                           <p className="paraCode"> <i className="indiceCode"> Description : </i>  {props.description}</p>
                        </Col>
                        <Col md="2">
                            {props.pdf === "pdf" ? 
                                <div>
                                {localStorage.getItem("username") !== null && localStorage.getItem("email") !== null?
                                    <PDFDownloadLink document={<PDFprodR yes={props.yes} certification={props.certification} ip={props.ip} coinO={props.coinO} accessoire={props.accessoire} coin={props.coin} codeProd={props.codeProd} sous={props.sous} finitionField={props.finitionField} longeur={props.longeur} driverc={props.driverc} />} fileName={props.codeProd+".pdf"}>
                                            {({ loading }) => (loading ? 'Loading document...' : 
                                            <div className="downloadFich"><FontAwesomeIcon icon={faDownload} className="DDICFich" onClick={SendData} />Fiche Technique</div>
                                            )}
                                    </PDFDownloadLink> 
                                    : 
                                    <div className="downloadFich"><FontAwesomeIcon icon={faDownload} onClick={FT} className="DDICFich" /> Fiche Technique</div>
                                }
                            </div>
                            :
                            ""
                            }
                        </Col>
                    </Row>  */}

                        {props.pdf === "pdf" ? 
                            <div class="cart-summary">
                                {localStorage.getItem("username") !== null && localStorage.getItem("email") !== null?
                                   <PDFDownloadLink document={<PDFprodR yes={props.yes} certification={props.certification} ip={props.ip} coinO={props.coinO} accessoire={props.accessoire} coin={props.coin} codeProd={props.codeProd} sous={props.sous} finitionField={props.finitionField} longeur={props.longeur} driverc={props.driverc} />} fileName={props.codeProd+".pdf"}>
                                {({ loading }) => (loading ? 'Loading document...' : 
                                    <div class="cart-summary-button">
                                         <div className="btn FicheTechnique" onClick={SendData}><FontAwesomeIcon icon={faDownload} /> <FormattedMessage id="fiche" defaultMessage="Fiche technique" /></div>
                                    </div>
                                    )}
                            </PDFDownloadLink>
                                    :
                                <div className="cart-summary-button">
                                    <button class="btn" onClick={FT}><FontAwesomeIcon icon={faDownload} /> <FormattedMessage id="fiche" defaultMessage="Fiche technique" /></button>
                                </div>
                            }
                            </div>
                                :
                                ""
                            }
            </div>
  ); 
} 

  export default React.memo(RailsCodePDF)