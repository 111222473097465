import React, {Component} from 'react';
import { Button, Col, Form, FormGroup, Row, Input, Container } from 'reactstrap';
import BaseSelect from 'react-select';
import RailsCodePDF from './RailsCodePDF.js';
import FixRequiredSelect from './FixRequiredSelect.js';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faTools } from '@fortawesome/free-solid-svg-icons';


class GeneratorFormRails extends Component{
        
  constructor(props){
    super(props);
    this.state={
        finitionField: "", pdf: "", finitionFieldData: "", generate: "", longeur: "", coin: "", driver: "", codeProd: "", Description: "", 
        typeCat: "", date:new Date().getDate()  + "-" + (new Date().getMonth()+1) + "-" + new Date().getFullYear(),
        month: new Date().getMonth()+1, finitionInitial: "", AddModalShow: false, IP: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ IP: nextProps.Ip });
  }


prodChange = (event) =>{ this.setState({ [event.target.name]:event.target.value }); }
LongeurChange = (e) =>{ this.setState({longeur: e.value}); }
CoinChange = (e) =>{ this.setState({coin: e.value}); }
DriverChange = (e) =>{ this.setState({Driver: e.value}); }
FinitionChange = (e) =>{ this.setState({finitionField: e.value}); }
IPChange = (e) =>{ this.setState({IP: e.value}); }

submitAllRails = (event) =>{
    event.preventDefault();
    this.setState({pdf:"pdf"}); this.setState({typeCat:this.props.data.typecategorie.nomType}); this.setState({generate:"g"})
      var nom =[]; var name= ""; var fini =[]; var fin = ""; var dim =[]; var c11 =""; var form =""; var drivert =this.state.Driver;
      var dr = ""; var productName = this.props.data.nomProduit; var productForm = ""; var productFinition = "";
      var productDriver = ""; var productDimension = "";
      nom = this.props.data.nomProduit.split(""); name= nom[0]+nom[1]+nom[2]+"-";
      dim = this.state.longeur.split(""); if(dim[0] === null){ c11="" }else{ c11 = dim[0] }
    if(this.state.finitionField === "Autre choix"){ fini = this.state.finitionInitial.split("");
        if(fini[0] == null){ fin="" }else{ fin = fini[0] } }
        if(this.state.finitionField !== "Autre choix"){ fini = this.state.finitionField.split("");
        if(fini[0] == null){ fin="" }else{ fin = fini[0] } }
    if(this.state.finitionField === "Autre choix"){ this.setState({finitionFieldData: this.state.finitionInitial}) }
    if(this.state.finitionField !== "Autre choix"){ this.setState({finitionFieldData: this.state.finitionField}) }
    if(drivert === "DMX"){ dr="DX" }
    if(drivert === "Dimmable" || drivert === "1-10V" || drivert === "0-10V"){ dr="D" }
    if(drivert === "DALI" ){ dr="DA" } if(drivert==="ON/OFF"){ dr=""; }
    if(this.state.Driver !== "undefined" && this.state.Driver !==null){ this.setState({driver: this.state.Driver}); productDriver=this.state.Driver } 
    if(this.props.data.form !== null && this.props.data.form !== "undefined" && this.props.data.form !== "" && this.props.data.form !== "NULL"){ form= this.props.data.form+"-"; }
    if(this.state.longeur !== null && this.state.longeur !== "undefined"){ productDimension= this.state.longeur; }
    if(this.state.finitionField !== null && this.state.finitionField !== "undefined"){ productFinition= this.state.finitionField; }

    var IPproduct= "";
    if(this.state.IP !== null && this.state.IP !== "" && this.state.IP !== "NULL" && this.state.IP !== "undefined"){
        IPproduct = "IP"+this.state.IP;
    }
    var IKproduct= "";
    if (this.props.data.ik !==null && this.props.data.ik !== "" && this.props.data.ik !== "NULL" && this.props.data.ik !== "undefined"){
        IKproduct= "IK"+this.props.data.ik;
    }  
this.setState({codeProd: name+form+fin+c11+dr})
this.setState({Description: productName+ "  " +productForm+ "  " +productFinition+ "  " +productDimension+ "  " +productDriver+ " "+IPproduct+ " "+IKproduct})


}

Ies = () =>{
    if(localStorage.getItem("email") !== null && localStorage.getItem("username") !== null){
        window.open(this.props.data.ies);
    }

}

    
render(){
    var dataLong=[];
    this.props.longeur.map((c)=>( dataLong = dataLong.concat([{ value: c.longeurName, label: c.longeurName }]) ))
    var datadriverR=[];
    this.props.driver.map((c)=>( datadriverR = datadriverR.concat([{ value: c.driverName, label: c.driverName }]) ))
    // var dataCoin=[];
    // this.props.coin.map((c)=>( dataCoin = dataCoin.concat([{ value: c.coinName, label: c.coinName }]) ))
    var dataFinition = [{value: this.props.data.finition , label: this.props.data.finition},{value: "Autre choix" , label: "Autre choix"}];
        // dataCoin = dataCoin.filter((item) => item.value !== ""); dataCoin = dataCoin.filter((item) => item.value !== "undefined");
        // dataCoin = dataCoin.filter((item) => item.value !== null); 
        dataLong = dataLong.filter((item) => item.value !== null);
        datadriverR = datadriverR.filter((item) => item.value !== null); dataLong = dataLong.filter((item) => item.value !== "undefined");
        dataFinition = dataFinition.filter((item) => item.value !== ""); dataFinition = dataFinition.filter((item) => item.value !== "undefined");
        dataFinition = dataFinition.filter((item) => item.value !== null); dataLong = dataLong.filter((item) => item.value !== "");
        
        var ipdata=[];
        this.props.ip.map((p)=>( ipdata = ipdata.concat([{ value: p.nombre, label: p.nombre }]) ))
        ipdata = ipdata.filter((item) => item.value !== ""); ipdata = ipdata.filter((item) => item.value !== null);
        
        const customStyles = { control: (base, state) => ({ ...base, background: " white", color: "black", height: 60, textAlign: "center", fontSize: 14, border: "1px solid black", cursor: "pointer" }) }; 

        const Select = (props) => (
            <FixRequiredSelect
              {...props}
              SelectComponent={BaseSelect}
            />
          );

    return(
        <Container> 
            <div>
                <Form onSubmit={this.submitAllRails} id="formId">
                    <Row style={{paddingLeft:0,paddingRight:0}}>
                        <Col lg="3" md="4" sm="12">
                            <FormGroup style={{pointerEvents: dataFinition.length === 0? "none": "all"}}>
                            <Select
                                placeholder="Finition"
                                value= {dataFinition.find(objpf => objpf.value === this.state.finitionField)}
                                options={dataFinition} 
                                onChange={this.FinitionChange} 
                                styles={customStyles}
                                required = {dataFinition.length === 0? "": true}
                            />
                        </FormGroup>
                        </Col>
                        <Col lg="3" md="4" sm="12">
                            <FormGroup style={{pointerEvents: datadriverR.length === 0? "none": "all"}} >
                                <Select
                                    placeholder="Driver"
                                    value= {datadriverR.find(objdriver => objdriver.value === this.state.Driver)}
                                    options={datadriverR} 
                                    onChange={this.DriverChange} 
                                    styles={customStyles}
                                    required = {datadriverR.length === 0? "": true}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="3" md="4" sm="12">
                            <FormGroup style={{pointerEvents: dataLong.length === 0? "none": "all"}} >
                                <Select
                                    placeholder="Dimension"
                                    value= {dataLong.find(objlong => objlong.value === this.state.longeur)}
                                    options={dataLong} 
                                    onChange={this.LongeurChange} 
                                    styles={customStyles}
                                    required = {dataLong.length === 0? "": true}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="3" md="4" sm="12">
                        <FormGroup style={{pointerEvents: ipdata.length <= 1? "none": "all"}} >
                            <Select
                                placeholder="IP"
                                value= {ipdata.find(objdriver => objdriver.value === this.state.IP)}
                                options={ipdata} 
                                onChange={this.IPChange} 
                                styles={customStyles}
                                required = {ipdata.length <= 1? "": true}
                            />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row style={{display:this.state.finitionField === "Autre choix"? "" : "none"}}>
                        <Col lg="4" md="4" sm="12">
                            <FormGroup>
                            <Input type="text" name="finitionInitial" value={this.state.finitionInitial} onChange={this.prodChange}  autoComplete="off" placeholder="Finition" style={{borderRadius:"none",borderTop:"none",borderRight:"none",borderLeft:"none",marginTop:10,height:50,pointerEvents:this.state.finitionField === "Autre choix"? "all" : "none"}}  />                
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className="row" style={{marginTop:50}}>
                    <div className="col-md-4 col-12 mb-25">
                        <div className="cart-summary">
                            <div className="cart-summary-button">
                                <Button type="submit" className="btn"><FontAwesomeIcon icon={faTools} /> <FormattedMessage id="config" defaultMessage="Configuration" /></Button>
                            </div>
                        </div>
                        {this.state.generate === "g" ?
                            <RailsCodePDF pdf={this.state.pdf} idProduit={this.props.idProduit} yes={this.props.data} description={this.state.Description} certification={this.props.certification} ip={this.props.ip} coinO={this.props.coin} accessoire={this.props.accessoire} coin={this.state.coin} codeProd={this.state.codeProd} sous={this.state.typeCat} finitionField={this.state.finitionFieldData} longeur={this.state.longeur} driverc={this.state.driver} />
                        : "" }
                        <div className="cart-summary" style={{pointerEvents:this.props.data.ies !== null && this.props.data.ies !== "" && this.props.data.ies !== "NULL"? "": "none"}}>
                            <div className="cart-summary-button">
                                <button className="btn" onClick={this.Ies}><FontAwesomeIcon icon={faFileDownload} /> <FormattedMessage id="ies" defaultMessage="Fichier IES" /> </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mb-25">
                        <div className="cart-summary">
                            <div className="cart-summary-wrap">
                                <h3>&#x2023;<b> <FormattedMessage id="code" defaultMessage="Code Produit" />:</b></h3><h3 style={{paddingLeft:40}}>{this.state.codeProd}</h3>
                                <h3>&#x2023;<b> <FormattedMessage id="desc" defaultMessage="Désignation du produit" />:</b></h3><h3 style={{paddingLeft:40}}>{this.state.Description}</h3>
                            </div>
                        </div>
                    </div>
                </div>






                    {/* <Row className="codeGenerate">
                        <Col md="12" style={{textAlign:"center"}}>
                            <div style={{width:"100%",textAlign:"center"}}>
                                <Button className="btnGenerate" type="submit">Envoyer</Button>
                            </div>
                        </Col>
                    </Row>
                    {this.state.generate === "g" ?

                    <RailsCodePDF pdf={this.state.pdf} idProduit={this.props.idProduit} yes={this.props.data} description={this.state.Description} certification={this.props.certification} ip={this.props.ip} coinO={this.props.coin} accessoire={this.props.accessoire} coin={this.state.coin} codeProd={this.state.codeProd} sous={this.state.typeCat} finitionField={this.state.finitionFieldData} longeur={this.state.longeur} driverc={this.state.driver} />
                    : "" } */}
                </Form>
            </div>
        </Container>
  ); } } export default React.memo(GeneratorFormRails)

  