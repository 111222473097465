import React from "react";


const Pagination = ({blogsPerPage, totalBlogs, paginate}) => {

    const pageNumbers = [];
    for(let i=1; i<=Math.ceil(totalBlogs / blogsPerPage); i++){
        pageNumbers.push(i);
    }
    
  return (
        <nav>
            <ul className="pagination">
                {pageNumbers.map(number =>(
                    <li key={number} className="page-item">
                        <div onClick={() => paginate(number)} className="page-link">
                            {number}
                        </div>
                    </li>
                ))}
            </ul>
        </nav>
      );
    };
    
export default  React.memo(Pagination);